import React from "react";
import { Link } from "react-router-dom";

export default () => {

    return (
        <>
            <footer className="footer_s">
                <div className="container">
                    <div className="footer_s_in">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer_s_link">
                                    <ul>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer_s_con text-end">
                                    <p>Copyrights © 2023. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
