import React from "react";
import window from "../assets/img/window.svg";
import ios from "../assets/img/ios.svg";
import android from "../assets/img/android.svg";
import searchnormal from "../assets/img/search-normal.svg";
import ic1 from "../assets/img/ic1.png";
import star from "../assets/img/star.svg";

const Listing1 = () => {

  return (
    <>

      <div class="main_sec">
        <section class="marwal_listing">
          <div class="container">
            <div class="marwal_listing_in">
              <div class="marwal_l_sdb">
                <h5>Filter By:</h5>
                <div class="marwal_l_links">
                  <div class="marwal_l_link">
                    <h6 class="marwal_l_link_heading">OS (Operating System)</h6>
                    <ul class="marwal_l_itms">
                      <li class="marwal_l_itm">
                        <a href="#"><span class="marwal_l_itm_ic"><img src={window} /></span> Windows</a>
                      </li>
                      <li class="marwal_l_itm">
                        <a href="#"><span class="marwal_l_itm_ic"><img src={ios} /></span> IOS</a>
                      </li>
                      <li class="marwal_l_itm">
                        <a href="#"><span class="marwal_l_itm_ic"><img src={android} /></span> Android</a>
                      </li>
                    </ul>
                  </div>
                  <div class="marwal_l_link marwal_l_link_dots">
                    <h6 class="marwal_l_link_heading">Languages</h6>
                    <ul class="marwal_l_itms">
                      <li class="marwal_l_itm"><a href="#">Python <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">Jupyter Notebook <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">C++ <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">HTML <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">C <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">Java <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">JavaScript <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">C# <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">Shell <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">PHP <span class="marwal_l_itm_counts">(32)</span></a></li>
                    </ul>
                  </div>
                  <div class="marwal_l_link marwal_l_link_dots">
                    <h6 class="marwal_l_link_heading">Category</h6>
                    <ul class="marwal_l_itms">
                      <li class="marwal_l_itm"><a href="#">Python <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">Jupyter Notebook</a></li>
                      <li class="marwal_l_itm"><a href="#">C++ <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">HTML <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">C</a></li>
                      <li class="marwal_l_itm"><a href="#">Java</a></li>
                      <li class="marwal_l_itm"><a href="#">JavaScript <span class="marwal_l_itm_counts">(32)</span></a></li>
                      <li class="marwal_l_itm"><a href="#">C#</a></li>
                      <li class="marwal_l_itm"><a href="#">Shell</a></li>
                      <li class="marwal_l_itm"><a href="#">PHP <span class="marwal_l_itm_counts">(32)</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="marwal_r">
                <div class="marwal_r_head">
                  <h4>Blitstect Monk</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <div class="marwal_r_meta">
                  <div class="marwal_r_meta_itm">12.2k results <span>(509 ms)</span></div>
                  <div class="marwal_r_meta_itm">Sort by: <span>Most Bookmarked</span></div>
                </div>
                <div class="search_itms">
                  <div class="search_itm">
                    <div class="search_itm_head">
                      <div class="search_itm_head_ic"><img src={ic1} /></div>
                      <h5>Blitstech the <a href="#">Marwal</a> Source Coders</h5>
                    </div>
                    <div class="search_itm_con">
                      <p>Collection of malware source code for a variety of platforms in an array of different programming languages. Collection of malware source code for a variety of platforms in an array of different programming languages.</p>

                      <div class="search_tags">
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                      </div>

                      <div class="search_ratings"><img src={star} /> 104k</div>
                    </div>
                  </div>
                  <div class="search_itm">
                    <div class="search_itm_head">
                      <div class="search_itm_head_ic"><img src={ic1} /></div>
                      <h5>Blitstech the <a href="#">Marwal</a> Source Coders</h5>
                    </div>
                    <div class="search_itm_con">
                      <p>Collection of malware source code for a variety of platforms in an array of different programming languages. Collection of malware source code for a variety of platforms in an array of different programming languages.</p>

                      <div class="search_tags">
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                      </div>

                      <div class="search_ratings"><img src={star} /> 104k</div>
                    </div>
                  </div>
                  <div class="search_itm">
                    <div class="search_itm_head">
                      <div class="search_itm_head_ic"><img src={ic1} /></div>
                      <h5>Blitstech the <a href="#">Marwal</a> Source Coders</h5>
                    </div>
                    <div class="search_itm_con">
                      <p>Collection of malware source code for a variety of platforms in an array of different programming languages. Collection of malware source code for a variety of platforms in an array of different programming languages.</p>

                      <div class="search_tags">
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                      </div>

                      <div class="search_ratings"><img src={star} /> 104k</div>
                    </div>
                  </div>
                  <div class="search_itm">
                    <div class="search_itm_head">
                      <div class="search_itm_head_ic"><img src={ic1} /></div>
                      <h5>Blitstech the <a href="#">Marwal</a> Source Coders</h5>
                    </div>
                    <div class="search_itm_con">
                      <p>Collection of malware source code for a variety of platforms in an array of different programming languages. Collection of malware source code for a variety of platforms in an array of different programming languages.</p>

                      <div class="search_tags">
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                        <div class="search_tag">Blitstect Monk</div>
                      </div>

                      <div class="search_ratings"><img src={star} /> 104k</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};


export default Listing1;
