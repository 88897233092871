import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute({ isLogged }) {

    useEffect(() => {
    }, [window.location.pathname]);

    const token = window.localStorage.getItem('accessToken');
    const permission = window.localStorage.getItem('permission');
    let logged = false;
    if (token !== null && permission === 'Member') {
        logged = true;
    } else {
        logged = false;
    }
    // return logged ? <div class="main_sec"><SideBar /><section class="main_content"><NavBar /><Outlet /></section></div> : <Navigate to="/" />;
    return logged ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;