import React, { useState } from "react";
import mailcontact from "../assets/img/mailcontact.svg";
import crosscontact from "../assets/img/crosscontact.svg";

const ContactUs = () => {
  const [isOpen, setIsOpen] = useState(0);
  const [oldIsOpen, setOldIsOpen] = useState(null);

  function dropdownOpen(number) {
    if (number === oldIsOpen) {
      setIsOpen(0);
      setOldIsOpen(null);
    } else {
      setIsOpen(number);
      setOldIsOpen(number);
    }
  }

  return (
    <>
      <div className="home_bg">
        <section className="home_search">
          <div className="container">
            <div className="home_features static_content_head">
              <div className="row jcenter">

                <div className="col-lg-12">
                  <div className="home_features_itm_cn">
                    <h1 className="heading_typ4contact">Contact Us</h1>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </section>
      </div>
      <section className="about_sec">
        <div className="container">
          <div className="about_sec_in">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="about_con">
                  <div className="scd_sec_con">
                    <h3 className="heading_type3">Let's Connect</h3>
                    <p className="sub_heading">Questions, suggestions, or exploring enterprise plans? Contact us for personalized assistance and information.</p>
                  </div>
                </div>

                <ul className="marwal_l_itms">
                  <li className="marwal_l_itm">
                    <span className="marwal_l_itm_ic contact-mail"><img src={mailcontact} /> <a href="mailto:contact@malsearch.com"> Contact@malsearch.com</a></span> 
                  </li>
                  <li className="marwal_l_itm">
                    <span className="marwal_l_itm_ic contact-mail"><img src={crosscontact} /> <a href="https://twitter.com/Malsearch0x"> MalSearch0x</a></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
