import React, { useState } from "react";
import emailsvg from "../assets/img/email.svg";
import { Link, useNavigate } from "react-router-dom";
import { postDataService } from "../userService";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState('');

  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!email) {
      setEmailError("Email is required!");
      return false;
    } else {
      setEmailError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      var user = {
        email: email
      }
      await postDataService('web/forgot_password', new URLSearchParams(user))
        .then(response => {
          if (response.status === 'success') {
            setIsAlertError('');
            setIsAlertSuccess(response.message);
            setEmail('');
          } else {
            setIsAlertSuccess('');
            setIsAlertError(response.message);
            setEmail('');
          }
        });
    }
  }

  return (
    <>
      <div class="main_sec">
        <div class="close-btn">
        </div>
        <div class="st_1">
          <div class="st_1_in">
            <h2 class="heading_typ1">Forgot Password</h2>
            <p className="sub_heading">Provide your account's email for which you want to reset your password</p>
            {isAlertError === '' ? '' :
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isAlertError}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "#f8d7da"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                </button>
              </div>
            }
            {isAlertSuccess === '' ? '' :
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{isAlertSuccess}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "rgb(209 231 221)"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                </button>
              </div>
            }
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email Address</label>
                    <div class="form-group-item">
                      <div class="form-group-img"><img src={emailsvg} /></div>
                      <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" class="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {emailError}
                    </small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <button class="btn btn_primary" type="submit" onClick={handleSubmit}>Request Reset Password Link</button>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <p><Link to="/login">Cancel</Link></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default ForgotPassword;