import React, { useEffect, useState } from "react";
import searchnormal from "../assets/img/search-normal.svg";
import filterMobile from "../assets/img/filter.svg";
import folderImage from "../assets/img/folder.png";
import fileImage from "../assets/img/sourceFile.png";
import star from "../assets/img/star.svg";
import cross from "../assets/img/cross.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postDataService } from "../userService";
import Highlighter from './high';
import LoaderComponent from "../component/LoaderComponent";
import DOMPurify from 'dompurify';
import Pagination from '../component/Pagination';
import ContentFilter from '../component/ContentFilter';

let PageSize = 10;

const Listing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavNew, setIsNavNew] = useState(false)
    const [categoryData, setCategoryData] = useState([]);
    const [technologyData, setTechnologyData] = useState([]);
    const [OSData, setOSData] = useState([]);
    const [sourceCodeList, setSourceCodeList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [filter, setFilter] = useState(location.state === null ? '' : location.state.filter);
    const [categoryId, setCategoryId] = useState(location.state === null ? [] : JSON.parse(location.state.categoryId));
    const [technologyId, setTechnologyId] = useState(location.state === null ? [] : JSON.parse(location.state.technologyId));
    const [osId, setOSId] = useState(location.state === null ? [] : JSON.parse(location.state.osId));
    const [typeId, setTypeId] = useState(location.state === null ? [] : JSON.parse(location.state.typeId));
    const [loading, setLoading] = useState(false);
    const [apiCallTime, setApiCallTime] = useState(null);
    const [currentPage, setCurrentPage] = useState(location.state === null ? 1 : location.state.currentPage);
    const [total, setTotal] = useState(0);
    const [codeFilter, setCodeFilter] = useState(false);
    const [typeCount, setTypeCount] = useState([]);
    const [filterError, setFilterError] = useState('');

    useEffect(() => {
        getData(currentPage, filter, categoryId, technologyId, osId, typeId);

        if (isNavNew) {
            document.body.classList.add('filter_active');
        } else {
            document.body.classList.remove('filter_active');
        }
    }, [isNavNew])

    useEffect(() => {
        if (location.state != null) {
            getData(currentPage, filter, categoryId, technologyId, osId, typeId);
        }
    }, [])

    const sanitizeInput = (input) => {
        // Use DOMPurify to sanitize and escape HTML entities
        return DOMPurify.sanitize(input);
    };


    async function getData(currentPage, filter, categoryId, technologyId, osId, typeId) {
        var data = {
            currentPage: currentPage,
            limit: PageSize,
            filter: filter,
            categoryId: JSON.stringify(categoryId),
            technologyId: JSON.stringify(technologyId),
            osId: JSON.stringify(osId),
            typeId: JSON.stringify(typeId)
        }
        setLoading(true);
        const startTime = performance.now();
        await postDataService(`web/get_bookmark`, new URLSearchParams(data))
            .then(response => {
                if (filter === '') {
                    setCodeFilter(false);
                } else {
                    setCodeFilter(true);
                }
                const endTime = performance.now();
                const timeTaken = endTime - startTime;
                if (!isNaN(timeTaken)) {
                    setApiCallTime(timeTaken.toFixed(2));
                } else {
                    setApiCallTime('N/A'); // or any default value
                }
                setLoading(false);
                if (response.status === 'success') {
                    setTypeCount(response.data.getTypeCount);
                    setTotal(response.data.sourceCodeList.total);
                    setCurrentPage(currentPage);
                    setSourceCodeList(response.data.sourceCodeList.productList);
                    setCategoryData(response.data.category);
                    setTechnologyData(response.data.technology);
                    setOSData(response.data.os);
                    setTotalRecord(response.data.sourceCodeList.total)
                } else {
                    console.log(response.message);
                }
            });
    }

    const handleFilterChange = e => {
        if (filter === "") {
            setCodeFilter(false);
        }
        if (e.key === "Enter") {
            if (filter.length === 1 || filter.length === 2) {
                setFilterError('Your search is too short, please try a search longer than 2 characters');
            } else {
                setFilterError('');
                getData(1, filter, categoryId, technologyId, osId, typeId);
            }
        }
    };

    function categoryFilter(Id) {
        const arrayMatch = [Id];
        const category = categoryId;
        const filteredArray = category.filter((item) => arrayMatch.includes(item.Id));
        if (filteredArray.length === 0) {
            category.push({ Id });
        } else {
            removeObjectWithId(category, Id);
        }
        setCategoryId(category);

        getData(1, filter, categoryId, technologyId, osId, typeId);
    };

    function technologyFilter(Id) {
        const arrayMatch = [Id];
        const technology = technologyId;
        const filteredArray = technology.filter((item) => arrayMatch.includes(item.Id));
        if (filteredArray.length === 0) {
            technology.push({ Id });
        } else {
            removeObjectWithId(technology, Id);
        }
        setTechnologyId(technology);

        getData(1, filter, categoryId, technologyId, osId, typeId);
    };

    function osFilter(Id) {

        const arrayMatch = [Id];
        const os = osId;
        const filteredArray = os.filter((item) => arrayMatch.includes(item.Id));
        if (filteredArray.length === 0) {
            os.push({ Id });
        } else {
            removeObjectWithId(os, Id);
        }
        setOSId(os);

        getData(1, filter, categoryId, technologyId, osId, typeId);
    };

    function typeFilter(Id) {

        const arrayMatch = [Id];
        const type = typeId;
        const filteredArray = type.filter((item) => arrayMatch.includes(item.Id));
        if (filteredArray.length === 0) {
            type.push({ Id });
        } else {
            removeObjectWithId(type, Id);
        }
        setTypeId(type);

        getData(1, filter, categoryId, technologyId, osId, typeId);
    };

    function removeObjectWithId(arr, Id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.Id === Id);

        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }

        return arr;
    }

    // Pagination
    function pageChange(page) {
        getData(page, filter, categoryId, technologyId, osId, typeId);
        window.scrollTo(0, 0)
    }

    // Nanigate
    function NavigateHandle(id, link) {
        const token = window.localStorage.getItem('accessToken');
        if (token === undefined || token === 'undefined' || token === null || token === 'null') {

            window.localStorage.setItem('page', '/bookmarked');
            window.localStorage.setItem('filter', filter);
            window.localStorage.setItem('typeId', JSON.stringify(typeId));
            window.localStorage.setItem('categoryId', JSON.stringify(categoryId));
            window.localStorage.setItem('technologyId', JSON.stringify(technologyId));
            window.localStorage.setItem('osId', JSON.stringify(osId));
            window.localStorage.setItem('currentPage', currentPage);
            navigate(`/login`);
        } else {
            window.localStorage.setItem('page', '/bookmarked');
            window.localStorage.setItem('filter', filter);
            window.localStorage.setItem('typeId', JSON.stringify(typeId));
            window.localStorage.setItem('categoryId', JSON.stringify(categoryId));
            window.localStorage.setItem('technologyId', JSON.stringify(technologyId));
            window.localStorage.setItem('osId', JSON.stringify(osId));
            window.localStorage.setItem('currentPage', currentPage);
            const newState = {
                filter: filter,
                link: link,
            };
            navigate(`/project-detail/${id}`, { state: newState });
        }
    }

    return (
        <>
            <div>
                {/* {/ Your other content goes here /} */}
                <LoaderComponent active={loading} />
            </div>
            < div className="home_bg" >
                <section className="marwal_listing">
                    <div className="container">
                        <div className="marwal_listing_in">
                            <div className="marwal_l_sdb">
                                <h5>Filter By:</h5>
                                <div className="marwal_l_links">
                                    <div className="marwal_l_link">
                                        <h6 className="marwal_l_link_heading">Types</h6>
                                        <ul className="marwal_l_itms">

                                            {typeCount.map((item, i) => {
                                                const isChecked = typeId.some(idObj => idObj.Id === item.type);
                                                return (
                                                    <li className="marwal_l_itm" key={i}>
                                                        <label class="custom_checkbox">{item.type === 'project' ? 'Projects' : 'Source Code'} <span className="marwal_l_itm_counts">({item.count})</span><input checked={isChecked} type="checkbox" onClick={(e) => typeFilter(item.type)} /><span class="checkmark"></span></label>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="marwal_l_link">
                                        <h6 className="marwal_l_link_heading">OS (Operating System)</h6>
                                        <ul className="marwal_l_itms">
                                            {OSData.map((item, i) => {
                                                const isChecked = osId.some(idObj => idObj.Id === item._id);
                                                return (
                                                    <li className="marwal_l_itm" key={i}>
                                                        <label class="custom_checkbox">{item.osName} <span className="marwal_l_itm_counts">({item.productCount})</span><input checked={isChecked} type="checkbox" onClick={(e) => osFilter(item._id)} /><span class="checkmark"></span></label>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="marwal_l_link marwal_l_link_dots">
                                        <h6 className="marwal_l_link_heading">Languages</h6>
                                        <ul className="marwal_l_itms">
                                            {technologyData.map((item, i) => {
                                                const isChecked = technologyId.some(idObj => idObj.Id === item._id);
                                                return (
                                                    <li className="marwal_l_itm" key={i}>
                                                        <li className="marwal_l_itm"><label class="custom_checkbox"> {item.technologyName} <span className="marwal_l_itm_counts">({item.productCount})</span><input type="checkbox" checked={isChecked} onClick={(e) => technologyFilter(item._id)} /><span class="checkmark"></span></label></li>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="marwal_l_link marwal_l_link_dots">
                                        <h6 className="marwal_l_link_heading">Category</h6>
                                        <ul className="marwal_l_itms">
                                            {categoryData.map((item, i) => {
                                                const isChecked = categoryId.some(idObj => idObj.Id === item._id);
                                                return (
                                                    <li className="marwal_l_itm" key={i}>
                                                        <li className="marwal_l_itm"> <label class="custom_checkbox">{item.categoryName} <span className="marwal_l_itm_counts">({item.productCount})</span><input checked={isChecked} type="checkbox" onClick={(e) => categoryFilter(item._id)} /><span class="checkmark"></span></label></li>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="cross_ic_itm" onClick={() => setIsNavNew(isNavNew => !isNavNew)}>
                                <a className="cross_ic" ><img src={cross} /></a>
                            </div>
                            <div className="marwal_r">
                                <small className="text-danger form-text">
                                    {filterError}
                                </small>
                                <div className="marwal_r_search">
                                    <div className="marwal_search_ic"><img src={searchnormal} /></div>
                                    <input type="text" placeholder="Start typing the code that you needed..." className="form-control" value={filter}
                                        onKeyPress={handleFilterChange} onChange={(e) => setFilter(e.target.value)} />
                                    <div className="filter_icon" onClick={() => setIsNavNew(isNavNew => !isNavNew)}>
                                        <img src={filterMobile} />
                                    </div>
                                </div>
                                <div className="marwal_r_meta">
                                    <div className="marwal_r_meta_itm">{totalRecord} results <span>({apiCallTime > 1000 ? (apiCallTime / 1000).toFixed(2) + ' Second' : apiCallTime + ' ms'})</span></div>
                                    <div className="marwal_r_meta_itm marwal_r_meta_drp">

                                    </div>
                                </div>
                                <div className="search_itms">
                                    {sourceCodeList.map((item, i) => {
                                        return (

                                            <div className="search_itm">
                                                {/* <Link className="link_file_manager" onClick={() => filterHandle(item._id, item.matchedLink)} to={"/project-detail/" + item._id}> */}
                                                <span className="link_file_manager" onClick={() => NavigateHandle(item._id, item.matchedLink === null || item.matchedLink === undefined ? '' : item.matchedLink.length > 0 ? item.matchedLink[0].filePath : '')}>
                                                    <div className="search_itm_head">
                                                        <div className="search_itm_head_ic"><img src={item.type === 'project' ? folderImage : fileImage} /></div>
                                                        <h5><Highlighter text={sanitizeInput(item.title)} searchTerm={sanitizeInput(filter)} /></h5>

                                                    </div>
                                                    {item.matchedLink === null || item.matchedLink === undefined || filter === '' || codeFilter === false ? '' : item.matchedLink.length > 0 ? <ContentFilter content={item.matchedLink[0].content} searchTerm={filter} /> : ''}

                                                    {/* <Link className="view_file_manager" style={{ "float": "right" }} to={"/project/" + item._id}><img className="eyeImage" src={eye} /></Link> */}
                                                    <div className="search_itm_con">
                                                        {/* <p><Highlighter text={sanitizeInput(item.shortDescription)} searchTerm={sanitizeInput(filter)} /></p> */}

                                                        <div className="search_tags mt-3">
                                                            {item.technologyList.map((technology, t) => {
                                                                return (
                                                                    // <div style={{ "color": technology.color }} className="search_tag">{technology.name}</div>
                                                                    <div className="search_tag">{technology.name}</div>
                                                                )
                                                            })}
                                                            {item.categoryList.map((category, t) => {
                                                                return (
                                                                    // <div style={{ "color": technology.color }} className="search_tag">{technology.name}</div>
                                                                    <div className="search_tag">{category.name}</div>
                                                                )
                                                            })}
                                                            {item.osList.map((os, t) => {
                                                                return (
                                                                    // <div style={{ "color": technology.color }} className="search_tag">{technology.name}</div>
                                                                    <div className="search_tag">{os.name}</div>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className="search_ratings"><img src={star} /> {item.bookMarkCount}</div>
                                                    </div>
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Pagination
                                    className="pagination-bar mt-3"
                                    currentPage={parseInt(currentPage)}
                                    totalCount={total}
                                    pageSize={PageSize}
                                    onPageChange={page => pageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};


export default Listing;