// // ContentFilter.js
// import React, { useState, useEffect } from 'react';

// const ContentFilter = ({ content, searchTerm }) => {
//     const [filteredContent, setFilteredContent] = useState("");

//     useEffect(() => {
//         // Escape special characters in the search term for use in regular expression
//         const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

//         // Define the pattern to match the line containing the dynamic search term
//         const pattern = new RegExp(`(.*${escapedSearchTerm}.*);`);

//         // Use the pattern to extract the line
//         const match = content.match(pattern);

//         // Check if there is a match
//         if (match && match.length > 0) {
//             // Extracted line containing the dynamic search term
//             const dynamicVarLine = match[1];

//             // Set the filtered content in the state
//             setFilteredContent(dynamicVarLine);
//         } else {
//             setFilteredContent(``);
//         }
//     }, [content, searchTerm]);

//     return (
//         <div>
//             <pre>{filteredContent}</pre>
//         </div>
//     );
// };

// export default ContentFilter;


// ContentFilter.js
// import React, { useState, useEffect } from 'react';

// const ContentFilter = ({ content, searchTerm }) => {
//   const [filteredContent, setFilteredContent] = useState([]);

//   useEffect(() => {
//     // Escape special characters in the search term for use in regular expression
//     const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

//     // Define the pattern to match the line containing the dynamic search term
//     const pattern = new RegExp(`(.*${escapedSearchTerm}.*);`, 'g');

//     // Use the pattern to extract all matching lines
//     const matches = content.match(pattern);

//     // Check if there are matches
//     if (matches && matches.length > 0) {
//       // Set the filtered content in the state
//       setFilteredContent(matches);
//     } else {
//       setFilteredContent([]);
//     }
//   }, [content, searchTerm]);

//   return (
//     <div>
//       {filteredContent.length > 0 ? (
//         <ul>
//           {filteredContent.map((line, index) => (
//             <li key={index}>
//               <pre>{line}</pre>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No matches found for '{searchTerm}'</p>
//       )}
//     </div>
//   );
// };

// export default ContentFilter;

// ContentFilter.js
// ContentFilter.js
import React, { useState, useEffect } from 'react';
import Highlighter from '../frontend/high';
import DOMPurify from 'dompurify';

const maxLength = 100; // Set your desired maximum length

// Assuming `line` is your content
// const truncatedLine = line.length > maxLength ? line.slice(0, maxLength) + '...' : line;

const ContentFilter = ({ content, searchTerm }) => {
    const [filteredContent, setFilteredContent] = useState([]);

    useEffect(() => {
        // Escape special characters in the search term for use in regular expression
        const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

        // Define the pattern to match the full line containing the search term
        const pattern = new RegExp(`.*${escapedSearchTerm}.*`, 'gi');

        // Use the pattern to find all occurrences of the search term
        const matches = content.match(pattern);

        // Check if there are matches
        if (matches && matches.length > 0) {
            // Set the filtered content in the state
            setFilteredContent(matches);
        } else {
            setFilteredContent([]);
        }
    }, [content, searchTerm]);

    const sanitizeInput = (input) => {
        // Use DOMPurify to sanitize and escape HTML entities
        return DOMPurify.sanitize(input);
    };

    function extractContext(line, searchTerm, maxLength) {
        const index = line.indexOf(searchTerm);
        const startIndex = Math.max(0, index - 45);
        const endIndex = Math.min(line.length, index + searchTerm.length + 45);
        return line.slice(startIndex, endIndex).trim() + (endIndex < line.length ? '...' : '');
    }

    return (
        <div>
            {filteredContent.length > 0 ? (
                <ul>
                    {filteredContent.map((line, index) => (
                        index === 0 ? <li key={index}>
                            <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {line.length > maxLength ? <Highlighter text={extractContext(line, searchTerm, 100)} searchTerm={sanitizeInput(searchTerm)} /> : <Highlighter text={line.trim()} searchTerm={sanitizeInput(searchTerm)} />}
                            </pre>
                        </li> : ''
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    );
};

export default ContentFilter;


