import React, { useEffect, useState } from "react";
import Ellipse1696 from "../assets/img/Ellipse 1696.svg";
import Ellipse1697 from "../assets/img/Ellipse 1697.svg";
import Ellipse1698 from "../assets/img/Ellipse 1698.svg";
import Ellipse1699 from "../assets/img/Ellipse 1699.svg";
import Ellipse1702 from "../assets/img/Ellipse 1702.svg";
import Ellipse1701 from "../assets/img/Ellipse 1701.svg";
import Ellipse1700 from "../assets/img/Ellipse 1700.svg";
import Ellipse1703 from "../assets/img/Ellipse 1703.svg";
import Ellipse1704 from "../assets/img/Ellipse 1704.svg";
import Ellipse17005 from "../assets/img/Ellipse 17005.svg";
import review1 from "../assets/img/review_1.png";
import review2 from "../assets/img/review_2.png";
import review3 from "../assets/img/review_3.png";
import Star2 from "../assets/img/Star 2.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import { getDataService } from "../userService";
import LoaderComponent from "../component/LoaderComponent";
import { useNavigate } from "react-router-dom";

const PricingPlan = () => {
   const navigate = useNavigate();
   const [data, setData] = useState([]);
   const [isDuration, setIsDuration] = useState('monthly');
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      getIndividualData();
   }, [isDuration])

   async function getIndividualData() {
      setLoading(true);
      await getDataService(`web/plan_page/${isDuration}`)
         .then(response => {
            setLoading(false);
            if (response.status === 'success') {
               setData(response.data);
            } else {
               console.log(response.message);
            }
         });
   }

   function filterHandle(plan) {
      navigate('/checkout', { state: plan });
   }


   return (
      <>
         <div>
            <LoaderComponent active={loading} />
         </div>
         <div className="main_sec">
            <div className="home_bg">
               <section className="home_search">
                  <div className="container">
                     <div className="row jcenter pricing_head mt-5">
                        <div className="col-lg-8 col-md-8 col-8">
                           <div className="home_features">
                              <h2 className="heading_typ4">Pricing Plan</h2>
                              <p className="sub_heading">Unlock premium access to MalSearch's extensive library of Malware Source Code and exclusive features</p>
                           </div>
                        </div>
                     </div>
                     <div className="row jcenter">
                        <div className="col-md-8">
                           <div className="pricing-switch">
                              <div className="switch-head">Pay Monthly</div>
                              <div className="switch">
                                 <label className="switch">
                                    <input type="checkbox" onChange={(e) => setIsDuration(isDuration === 'yearly' ? 'monthly' : 'yearly')} checked={isDuration === 'yearly' ? true : false} />
                                    <span className="slider round"></span>
                                 </label>
                              </div>
                              <div className="switch-head">Pay Yearly</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            <div className="pricing-section">
               <section className="py-5">
                  <div className="container">
                     <div className="jcenter d-flex pricing_itms">
                        {[
                           // Find the item with type "price" and move it to index 0
                           ...data.filter(item => item.type === 'price'),
                           // Move all other items to the subsequent indices
                           ...data.filter(item => item.type !== 'price'),
                        ].map((item, i) => {
                           return (
                              <div className="card-set">
                                 <div className="card card-dark mb-5 mb-lg-0 rounded-lg shadow h-100">
                                    <div className="card-header card-header-brdr card-header-strip ">
                                       <h6 className="card-title text-white text-center font-w-4">{item.name}</h6>
                                    </div>
                                    <div className="card-body flx_body rounded-bottom">
                                       <div className="ddd">
                                          <h6 className="card-title text-white text-center font-w-4 txt-light mt-3">{item.name}</h6>
                                          {item.type === 'price' ?
                                             <h6 className="h1 text-white text-center mt-3 font-w-7">${item.totalPrice}<span className="h6 text-white"> {isDuration === 'yearly' ? 'Yearly' : 'Monthly'}</span></h6>
                                             : <h6 className="h1 text-white text-center mt-3 font-w-7">Custom <span className="h6 text-white">Plan</span></h6>}
                                          <div className="price text-center mt-3 d-flex">
                                             {item.type === 'price' ?
                                                item.discountPrice === null ? '' : item.discountPrice === 0 ? '' :
                                                   <del className="mt-2">${item.price}</del>
                                                : ''}
                                             {item.type === 'price' ?
                                                item.discount === null ? '' : item.discount === 0 ? '' :
                                                   <div className="btn-theme-bg">Save {item.discount}%</div> : ''}
                                          </div>
                                          <h5 className="card-title text-white mt-4">What’s included :</h5>
                                          <ul className="mb-4 mt-3 discribe">
                                             {item.featureList.map((features, j) => {
                                                return (
                                                   <li className="mb-3">{features.name}</li>
                                                )
                                             })}
                                          </ul>
                                       </div>
                                       <div className="ddd">
                                          {item.type === 'price' ?
                                             <button className="btn btn-block W-100 btn-theme-2 font-w-8 rounded-lg py-3 mt-5 mb-3" onClick={() => filterHandle(item)}>Choose Plan</button>
                                             : <Link to="/contact-us" className="btn btn-block W-100 btn-theme-2 font-w-8 rounded-lg py-3 mt-5 mb-3">Contact Us</Link>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  </div>
               </section>
            </div>
            <div className="team-carousel py-5 mt-5">
               <div className="extra-images">
                  <img className="img-fluid img-1" src={Ellipse1696} />
                  <img className="img-fluid img-2" src={Ellipse1697} />
                  <img className="img-fluid img-3" src={Ellipse1698} />
                  <img className="img-fluid img-4" src={Ellipse1699} />
                  <img className="img-fluid img-5" src={Ellipse1702} />
                  <img className="img-fluid img-6" src={Ellipse1701} />
                  <img className="img-fluid img-7" src={Ellipse1700} />
                  <img className="img-fluid img-8" src={Ellipse1703} />
               </div>
               <div className="container-fluid">
                  <OwlCarousel className='owl-theme' loop margin={20} items={1} nav>
                     <div className="item">
                        <div className="item-section text-center">
                           <h3 className="heading_type3 mt-3 mb-8">Our Reviews</h3>
                           <div className="profile-img mt-5">
                              <span className="box-line">
                                 <img src={Ellipse1704} />
                              </span>
                              <span className="box-line-2">
                                 <img src={Ellipse17005} />
                              </span>
                              <img className="img-fluid" src={review1} />
                           </div>
                           <div className="profile-content mt-5 mb-3">
                              <p className="sub_heading-2">MalSearch is my go-to for staying updated on the latest Malware Projects and Source code. The robust filtering options by OS, programming language, and malware category make it highly recommended for staying informed and finding malware projects.
                              </p>
                           </div>
                           <div className="rating">
                              <ul className="list-unstyled">
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                              </ul>
                           </div>
                           <h4 className="card-title text-white mt-3">@NotZvxn</h4>
                        </div>
                     </div>
                     <div className="item">
                        <div className="item-section text-center">
                           <h3 className="heading_type3 mt-3">Our Reviews</h3>
                           <div className="profile-img mt-5">
                              <span className="box-line">
                                 <img src={Ellipse1704} />
                              </span>
                              <span className="box-line-2">
                                 <img src={Ellipse17005} />
                              </span>
                              <img className="img-fluid" src={review2} />
                           </div>
                           <div className="profile-content mt-5 mb-3">
                              <p className="sub_heading-2">MalSearch is an indispensable tool for navigating the intricate world of malware. Its rich repository, intuitive interface, and powerful filtering options by OS, programming language, and malware category make it a standout. The ability to seamlessly analyze, download, and bookmark projects adds another layer of convenience. Highly impressed and recommended for any cybersecurity professional.
                              </p>
                           </div>
                           <div className="rating">
                              <ul className="list-unstyled">
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                              </ul>
                           </div>
                           <h4 className="card-title text-white mt-3">@a_daudjee</h4>
                        </div>
                     </div>
                     <div className="item">
                        <div className="item-section text-center">
                           <h3 className="heading_type3 mt-3">Our Reviews</h3>
                           <div className="profile-img mt-5">
                              <span className="box-line">
                                 <img src={Ellipse1704} />
                              </span>
                              <span className="box-line-2">
                                 <img src={Ellipse17005} />
                              </span>
                              <img className="img-fluid" src={review3} />
                           </div>
                           <div className="profile-content mt-5 mb-3">
                              <p className="sub_heading-2">MalSearch revolutionized my malware analysis. Expansive code, user-friendly features, and comprehensive filtering for cybersecurity professionals.
                              </p>
                           </div>
                           <div className="rating">
                              <ul className="list-unstyled">
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                                 <li><img className="img-fluid" src={Star2} /></li>
                              </ul>
                           </div>
                           <h4 className="card-title text-white mt-3">@4straSec</h4>
                        </div>
                     </div>
                  </OwlCarousel>
               </div>
            </div>
         </div>
      </>
   );
};


export default PricingPlan;
