import React, { useEffect, useState } from "react";
import { DiCss3, DiHtml5, DiJavascript, DiNpm, DiPhp } from "react-icons/di";
import { FaImage, FaInfo, FaVideo } from 'react-icons/fa';
import { FaList, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "../assets/css/styleTree.css";
import CodeViewer from "../component/CodeViewer";
import tag from "../assets/img/tag.svg";
import star_empty from "../assets/img/star_empty.svg";
import starNew from "../assets/img/star 1.svg";
import report from "../assets/img/report.svg";
import star from "../assets/img/star.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDataService, postDataService, serverLink } from "../userService";
import LoaderComponent from "../component/LoaderComponent";
import DOMPurify from 'dompurify';
import moment from 'moment';
import folderImage from "../assets/img/folder.png";
import fileImage from "../assets/img/sourceFile.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
var FileSaver = require('file-saver');

const ProjectDetail = () => {
  var params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [bookMarkStatus, setBookMarkStatus] = useState(false);
  const [bookMarkCount, setBookMarkCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [issue, setIssue] = useState('');
  const [productId, setProductId] = useState();
  const [issueError, setIssueError] = useState('');

  // Modal Control
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // File Manager
  const [fileCode, setFileCode] = useState('No File Selected!');
  const [isBinary, setIsBinary] = useState(false);
  const [fileLine, setFileLine] = useState(0);
  const [dataNew, setDataNew] = useState();
  const [dataNewLink, setDataNewLink] = useState();
  const [title, setTitle] = useState();

  // const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [technologyList, setTechnologyList] = useState([]);
  const [osList, setOsList] = useState([]);
  const [isFile, setIsFile] = useState('file');
  const [isFileLink, setIsFileLink] = useState(true);

  async function handleShow(productId) {
    setProductId(productId);
    setShow(true);
  }

  useEffect(() => {
    if (location.state != null) {
      const filePath = location.state.link;
      // Split the path using the '/' delimiter and get the last element (filename)
      const fileName = filePath.split('/').pop();
      setBreadcrumbs(fileName);
      openFile(location.state.link, '', fileName, true);
      if (Object.keys(params).length !== 0) {
        getIndividualData();
        getFileManagerData(fileName, filePath);
      }
    } else {
      if (Object.keys(params).length !== 0) {
        getIndividualData();
        getFileManagerData();
      }
    }

  }, [])

  async function getIndividualData() {
    setLoading(true);
    await getDataService(`web/source_detail/${params._id}`)
      .then(response => {
        setLoading(false);
        if (response.status === 'success') {
          if (response.isPlan) {
            setData(response.data);
            setBookMarkStatus(response.bookmark.bookMarkStatus);
            setBookMarkCount(response.bookmark.totalBookMark);
          } else {
            navigate('/plans')
          }
        } else {
          // toast.dismiss();
          console.log(response.message)
          // toast.error(response.message);
        }
      });
  }

  async function copyCode(projectId) {
    setLoading(true);
    var data = {
      projectId: projectId
    }
    await postDataService(`web/add_copy_code`, new URLSearchParams(data))
      .then(response => {
        setLoading(false);
        if (response.status === 'success') {
          // Select the content to be copied
          var contentToCopy = document.getElementById('codeViewerSyntaxHighlighter');
          var range = document.createRange();
          range.selectNode(contentToCopy);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);

          try {
            // Execute the copy command
            document.execCommand('copy');
            toast.success('Code copied to clipboard!');
          } catch (err) {
            toast.error('Unable to copy code', err);
          }

          // Clear the selection
          window.getSelection().removeAllRanges();
        } else {
          toast.error(response.message);
        }
      });

  }

  async function bookMark(projectId, status) {
    setLoading(true);
    var data = {
      projectId: projectId,
      status: status
    }
    await postDataService(`web/add_bookmark`, new URLSearchParams(data))
      .then(response => {
        setLoading(false);
        if (response.status === 'success') {
          getIndividualData();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  }

  async function downloadCode(filePath, title, productId) {
    setLoading(true);
    var data = {
      projectId: productId
    }
    await postDataService(`web/add_download`, new URLSearchParams(data))
      .then(response => {
        setLoading(false);
        if (response.status === 'success') {
          toast.success(response.message);
          const token = window.localStorage.getItem('accessToken');
          downloadFile(serverLink, filePath, title, token);
          // FileSaver.saveAs(`${serverLink + '' + filePath}`, title + ".zip");
          // getIndividualData();
        } else {
          toast.error(response.message);
        }
      });

  }

  const downloadFile = async (serverLink, filePath, title, token) => {
    try {
      const url = `${serverLink + '' + filePath}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();

      FileSaver.saveAs(blob, title + '.zip');
    } catch (error) {
      console.error('Error downloading file:', error.message);
    }
  };

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!issue) {
      setIssueError("Enter any issue!");
      return false;
    } else {
      setIssueError("");
      formIsValid = true;
    }
    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      var data = {
        projectId: productId,
        issue: issue
      }
      setLoading(true);
      await postDataService(`web/add_report`, new URLSearchParams(data))
        .then(response => {
          setLoading(false);
          if (response.status === 'success') {
            toast.success(response.message);
            setShow(false);
            setIssue('');
          } else {
            toast.error(response.message);
          }
        });
    }
  }

  const findAllParentsIdsByPath = (tree, targetPath) => {
    const result = [];

    const findInTree = (node, path) => {
      if (node.metadata && node.metadata.path === path) {
        result.push(node.id);
        return true;
      }

      if (node.children) {
        for (const childId of node.children) {
          const childNode = tree.find(item => item.id === childId);
          if (childNode && findInTree(childNode, path)) {
            result.push(node.id);
            return true;
          }
        }
      }

      return false;
    };

    for (const node of tree) {
      if (findInTree(node, targetPath)) {
        break;
      }
    }

    return result.reverse(); // Reverse to get the parent ids in the correct order
  };

  // File Manager
  // Get Feature List
  async function getFileManagerData(fileName, filePath) {
    try {
      await getDataService(`get_product_details/${params._id}`)
        .then(response => {
          if (response.status === 'success') {
            if (response.isPlan) {
              if (location.state) {

              } else {
                setBreadcrumbs(response.data.projectDetail.title);
              }
              setTitle(response.data.projectDetail.title);
              setCategoryList(response.data.projectDetail.categoryList);
              setTechnologyList(response.data.projectDetail.technologyList);
              setOsList(response.data.projectDetail.osList);

              if (response.data.projectDetail.type === 'sourceCode') {
                if (response.data.allFiles[0].type === 'file') {
                  openFile(response.data.allFiles[0].metadata.path, '', response.data.allFiles[0].name, false)
                }
              }

              const folder = {
                name: "",
                children: response.data.allFiles,
              };

              const data = flattenTree(folder);

              var parents = [];
              if (filePath != undefined) {
                const treeData = data;
                const Path = filePath;
                parents = findAllParentsIdsByPath(treeData, Path);
              }


              setDataNew(<TreeView
                data={data}
                aria-label="directory tree"
                expandedIds={parents}
                togglableSelect
                clickAction="EXCLUSIVE_SELECT"
                multiSelect
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                  handleSelect
                }) => (
                  <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                    {isBranch || element.metadata.type === 'folder' ? (
                      <>
                        <FolderIcon isOpen={true} />
                        <span>{element.name}</span>
                      </>
                    ) : (
                      <>
                        <FileIcon filename={element.name} />
                        <span onClick={() => openFile(element.metadata.path, element, element.name, false)}>{element.name}</span>
                      </>
                    )}
                  </div>
                )}
              />);

              setDataNewLink(<TreeView
                data={data}
                aria-label="directory tree"
                expandedIds={parents}
                togglableSelect
                clickAction="EXCLUSIVE_SELECT"
                multiSelect
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                  handleSelect
                }) => (
                  <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                    {isBranch || element.metadata.type === 'folder' ? (
                      <>
                        <FolderIcon isOpen={true} />
                        <span>{element.name}</span>
                      </>
                    ) : (
                      <>
                        <FileIcon filename={element.name} />
                        {fileName === element.name ? <span style={{ "background": "#306bb0" }} onClick={() => openFile(element.metadata.path, element, element.name, false)}>{element.name}</span> :
                          <span onClick={() => openFile(element.metadata.path, element, element.name, false)}>{element.name}</span>}
                      </>
                    )}
                  </div>
                )}
              />);
            } else {
              navigate('/plans')
            }

          } else {
            // toast.dismiss();
            // toast.error(response.message);
            console.log(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }

  }

  async function openFile(filePath, element, fileName, status) {

    setIsFileLink(status);
    var pathLink = filePath;
    // const pathSegments = pathLink.split("uploads");
    const pathSegments = pathLink;
    setBreadcrumbs(fileName);
    const extension = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'tif' || extension === 'tiff' || extension === 'gif') {
      setIsFile('image');
      var url = serverLink + '/resources/uploads/' + pathSegments;
      callPdfImage(url, extension);
      // setFileCode(serverLink + '/resources/uploads' + pathSegments);
    } else if (extension === 'pdf') {
      setIsFile('pdf');
      var url = serverLink + '/resources/uploads/' + pathSegments;
      callPdfImage(url, extension);
    } else if (extension === 'mp4') {
      setIsBinary(true);
      setFileCode(`This file cannot be opened here, please “Download” to access it’s contents. 
<This message occurs for Binary files that are not Human Readable or file extensions that are not supported on our editor>`);
      setFileLine(0);
    } else {
      setIsFile('file');
      var data = {
        path: filePath
      }
      // Get File Data
      setLoading(true);
      await postDataService(`get_file_code`, new URLSearchParams(data))
        .then(response => {
          setLoading(false);
          if (response.status === 'success') {
            setIsBinary(response.isBinary);
            if (response.isBinary) {
              setFileCode(`This file cannot be opened here, please “Download” to access it’s contents. 
<This message occurs for Binary files that are not Human Readable or file extensions that are not supported on our editor>`);
              setFileLine(0);
            } else {
              setFileCode(response.data.code);
              setFileLine(response.data.lines);
            }

          } else {
            console.log(response.message);
          }
        });
    }
  }

  function callPdfImage(url, type) {
    var jwtToken = window.localStorage.getItem('accessToken'); // Replace with your actual JWT token
    // var url = serverLink + '/resources/uploads/' + pathSegments;

    // Use the fetch API to send the request with headers
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + jwtToken,
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); // Assuming the response is a binary blob (PDF file)
      })
      .then(blob => {
        // Use a suitable method to display the PDF, e.g., using the PDF.js library
        if (type == 'pdf') {
          displayPdf(blob);
        } else {
          displayImage(blob)
        }

      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  function displayPdf(blob) {
    // Example using PDF.js library to display the PDF
    // Make sure to include the PDF.js library in your project
    // This is just a simple example, you may need to adapt it based on your actual requirements

    var pdfUrl = URL.createObjectURL(blob);

    // Use PDF.js to display the PDF
    var viewerId = 'pdfViewer'; // Replace with your viewer element ID
    var pdfViewer = document.getElementById(viewerId) || document.body;

    // Clear existing content of the viewer element
    pdfViewer.innerHTML = '';

    var iframe = document.createElement('iframe');
    iframe.src = pdfUrl;
    iframe.style.width = '100%';
    iframe.style.height = '100vh';

    pdfViewer.appendChild(iframe);
  }

  function displayImage(blob) {
    // Example to display an image
    // This is just a simple example, you may need to adapt it based on your actual requirements

    var imageUrl = URL.createObjectURL(blob);

    // Use an img element to display the image
    var viewerId = 'imageViewer'; // Replace with your viewer element ID
    var imageViewer = document.getElementById(viewerId) || document.body;

    // Clear existing content of the viewer element
    imageViewer.innerHTML = '';

    var img = document.createElement('img');
    img.src = imageUrl;
    img.style.width = '100%';
    img.style.height = 'auto'; // Adjust the height based on your requirements

    imageViewer.appendChild(img);
  }

  const FolderIcon = ({ isOpen }) =>
    isOpen ? (
      <FaRegFolderOpen color="e8a87c" className="icon" />
    ) : (
      <FaRegFolder color="e8a87c" className="icon" />
    );

  const FileIcon = ({ filename }) => {
    const extension = filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
    switch (extension) {
      case "js":
        return <DiJavascript color="yellow" className="icon" />;
      case "php":
        return <DiPhp color="blue" className="icon" />;
      case "jsx":
        return <DiJavascript color="yellow" className="icon" />;
      case "css":
        return <DiCss3 color="turquoise" className="icon" />;
      case "json":
        return <FaList color="yellow" className="icon" />;
      case "npmignore":
        return <DiNpm color="red" className="icon" />;
      case "html":
        return <DiHtml5 color="yellow" className="icon" />;
      case "png":
        return <FaImage color="blue" className="icon" />;
      case "jpg":
        return <FaImage color="blue" className="icon" />;
      case "jpeg":
        return <FaImage color="blue" className="icon" />;
      case "gif":
        return <FaVideo color="blue" className="icon" />;
      case "tif":
        return <FaImage color="blue" className="icon" />;
      case "tiff":
        return <FaImage color="blue" className="icon" />;
      case "md":
        return <FaInfo color="blue" className="icon" />;
      default:
        return null;
    }
  };

  function goBack() {
    navigate(window.localStorage.getItem('page') ? window.localStorage.getItem('page') : -1, {
      replace: true, state: {
        filter: window.localStorage.getItem('filter'),
        currentPage: window.localStorage.getItem('currentPage'),
        typeId: window.localStorage.getItem('typeId'),
        technologyId: window.localStorage.getItem('technologyId'),
        categoryId: window.localStorage.getItem('categoryId'),
        osId: window.localStorage.getItem('osId'),
        sortBy: window.localStorage.getItem('sortBy'),
      }
    }); // Navigate back in history
  }

  return (
    <>
      <div>
        <ToastContainer closeButton={true} position="top-right" />
        <LoaderComponent active={loading} />
      </div>
      <div class="main_sec">
        <div class="home_bg">
          <section class="marwal_listing">
            <div class="container">
              {data.map((item, i) => {
                return (<>
                  <div class="marwal_detail_in">
                    <div class="detail_meta">
                      <div class="search_itm_head">
                        <div class="search_itm_head_ic"><img src={item.type === 'project' ? folderImage : fileImage} /></div>
                        <h5>{item.title}</h5>
                      </div>
                      <div class="detail_meta_itms">
                        <div class="detail_meta_itm"><button class="btn btn_info btn-round" onClick={() => downloadCode(item.zipFile, item.title, item._id)}>Download</button></div>
                        <div class="detail_meta_itm"><button class="btn btn_primary btn-round" onClick={() => copyCode(item._id)} >Copy Code</button></div>
                        <div class="detail_meta_itm"><button class="btn btn_info btn-round" onClick={() => bookMark(item._id, bookMarkStatus)}><img src={bookMarkStatus ? starNew : star_empty} /></button></div>
                        <div class="detail_meta_itm"><button class="btn btn_info btn-round" onClick={() => handleShow(item._id)}><img src={report} /></button></div>
                        <div class="detail_meta_itm"><button class="btn btn_primary btn-round" onClick={() => goBack()} >Back to Search Results</button></div>
                      </div>
                    </div>
                    <div class="detail_ratings">
                      <div class="search_ratings"><img src={star} /> {bookMarkCount}</div>
                      <div class="detail_update">{item.lastUpdated === undefined ? 'Created ' + moment(item.createdAt).fromNow() : 'Updated ' + moment(item.lastUpdated).fromNow()}</div>
                    </div>
                  </div>
                  <div class="marwal_detail mt-4 gap-4">
                    <div className="sidebar-maneg-1">
                      <div class="detail_sidebar ms-0">
                        <h5>Folders</h5>
                        <div className="code_section_sidebar mt-3">
                          <div className="ide">
                            {isFileLink ? dataNewLink : dataNew}
                          </div>
                        </div>
                      </div>
                      <div class="detail_sidebar mt-4 ms-0">
                        <div class="detail_sidebar_itm">
                          <h5>Operating System</h5>
                          <ul class="marwal_l_itms">
                            {item.osList.map((os, j) => {
                              return (
                                <li class="marwal_l_itm"><span href="#">{os.name}</span></li>
                              )
                            })}
                            {item.osList.length === 0 ? '-' : ''}
                          </ul>
                        </div>
                        <div class="detail_sidebar_itm">
                          <h5>Technologies</h5>
                          <ul class="marwal_l_itms">
                            {item.technologyList.map((technology, j) => {
                              return (
                                <li class="marwal_l_itm"><span href="#">{technology.name}</span></li>
                              )
                            })}
                            {item.technologyList.length === 0 ? '-' : ''}
                          </ul>
                        </div>
                        <div class="detail_sidebar_itm mt-4">
                          <h5>Categories</h5>
                          <ul class="marwal_l_itms">
                            {item.categoryList.map((category, j) => {
                              return (
                                <li class="marwal_l_itm"><span class="" href="#">{category.name}</span></li>
                              )
                            })}
                            {item.categoryList.length === 0 ? '-' : ''}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="marwal_detail_in box-code-view">
                      <div class="detail_content mt-0">
                        <div className="code_nav content_cards top-line-bar">
                          <div className="code_nav_itm">
                            <div className="file-name-opn">
                              {breadcrumbs}
                            </div>
                          </div>
                          <div className="code_nav_itm">
                            <span>
                              {fileLine} lines
                            </span>
                          </div>
                        </div>
                        <div className="code_section">
                          <div className="code_section_content p-0 mange-pading">
                            {isFile === 'image' ? <div id="imageViewer" /> : isFile === 'pdf' ? <div id="pdfViewer" /> : isBinary ? <span className="text-danger"><pre>{fileCode}</pre></span> : <CodeViewer isBinary={isBinary} code={fileCode} search={location.state === null ? '' : location.state.filter} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </section>
        </div>
      </div>

      <Modal size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="tab-content">
            <textarea className="form-control" onChange={(e) => setIssue(e.target.value)} placeholder="Enter issue" rows="4" cols="50"></textarea>
            <small className="text-danger form-text">
              {issueError}
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button style={{ "color": "white", "background": "black" }} type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ProjectDetail;