import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import '../assets/css/style.css';

const LoaderComponent = ({ active }) => {
    return (
        <LoadingOverlay
            active={active}
            spinner
            text="Loading..."
            styles={{
                overlay: base => ({
                    ...base,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }),
                spinner: base => ({
                    ...base,
                    width: '100px',
                    height: '100px',
                }),
            }}
        >
            {/* {/ Your content goes here /} */}
        </LoadingOverlay>
    );
};

export default LoaderComponent;