import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import taz from "../assets/img/taz.svg";
import Mastercard_logo from "../assets/img/Mastercard_logo-removebg-preview 1.png";
import roundicon from "../assets/img/round-icon.svg";
import { getDataService, postDataService } from "../userService";
import LoaderComponent from "../component/LoaderComponent";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardUpdate from "../component/CardUpdate";
import config from "../config";

// Publishable key
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);


const Account = () => {
   const navigate = useNavigate();
   const [isActive, setIsActive] = useState('personal');
   const [loading, setLoading] = useState(false);
   const [isButton, setIsButton] = useState(false);
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [email, setEmail] = useState();
   const [twofaEnabled, setTwofaEnabled] = useState('');
   const [lastLogin, setLastLogin] = useState();
   const [firstNameError, setFirstNameError] = useState('');
   const [lastNameError, setLastNameError] = useState('');
   const [verificationCodeError, setVerificationCodeError] = useState('');
   const [isAlertSuccess, setIsAlertSuccess] = useState('');
   const [isAlertError, setIsAlertError] = useState('');
   const [loginHistory, setLoginHistory] = useState([]);
   const [planData, setPlanData] = useState([]);
   const [verificationCode, setVerificationCode] = useState();
   const [amount, setAmount] = useState(0);
   const [cardLastDigit, setCardLastDigit] = useState('');
   const [enterprisePlan, setEnterprisePlan] = useState(false);

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);

   const [showCard, setShowCard] = useState(false);
   const handleCloseCard = () => setShowCard(false);

   const [cardName, setCardName] = useState();
   const [cardNameError, setCardNameError] = useState('');
   const [paymentError, setPaymentError] = useState(null);

   const allowedCharacters = /^[a-zA-Z]+$/; // Only allow alphabetical characters

   useEffect(() => {
      getIndividualData();
   }, [])

   async function getIndividualData() {
      try {
         setLoading(true);
         await getDataService(`web/profile`)
            .then(async response => {
               setLoading(false);
               if (response.status === 'success') {
                  setCardLastDigit(response.data.last4digit);
                  setAmount(response.data.amount);
                  setLastLogin(response.data.userData.createdAt);
                  setLoginHistory(response.data.loginHistory);
                  setPlanData(response.data.planData);
                  setFirstName(response.data.userData.firstName);
                  setEnterprisePlan(response.data.userData.enterprisePlan);
                  setLastName(response.data.userData.lastName);
                  setEmail(response.data.userData.email);
                  setTwofaEnabled(response.data.userData.twofaEnabled === false ? 'off' : 'on');
               } else {
                  console.log(response.status)
               }
            });
      } catch (error) {
         console.log(error.message);
      }

   }

   // Validation
   const handleValidation = (name, value) => {
      let isValid = true;
      const nameRegex = /^[A-Za-z\s]+$/;

      if (name === 'firstName') {
         if (!value || value.trim() === '') {
            setFirstNameError('First name is required!');
            isValid = false;
         } else if (value.length > 50) {
            setFirstNameError('First name must be limited to 50 characters!');
            isValid = false;
         } else if (!nameRegex.test(value)) {
            setFirstNameError('Invalid first name!');
            isValid = false;
         } else {
            setFirstNameError('');
         }
      } else if (name === 'lastName') {
         if (value.length > 50) {
            setLastNameError('Last name must be limited to 50 characters!');
            isValid = false;
         } else if (!nameRegex.test(value)) {
            setLastNameError('Invalid last name!');
            isValid = false;
         } else {
            setLastNameError('');
         }
      }

      return isValid;
   };

   // Validation For 2FA Off
   const handleValidationAuthentication = (event) => {
      let formIsValid = false;
      if (!verificationCode) {
         setVerificationCodeError("Verification code is required!");
         return false;
      } else {
         setVerificationCodeError("");
         formIsValid = true;
      }

      return formIsValid;
   };

   const handleFirstNameChange = (event) => {
      const value = event.target.value;
      if (allowedCharacters.test(value) || value === '') {
         setFirstName(value);
         setIsButton(handleValidation('firstName', value));
      }

   };

   const handleLastNameChange = (event) => {
      const value = event.target.value;
      if (allowedCharacters.test(value) || value === '') {
         setLastName(value);
         setIsButton(handleValidation('lastName', value));
      }
   };

   const handleSubmit = async event => {
      event.preventDefault();
      if (handleValidation()) {

         var featureData = {
            firstName: firstName,
            lastName: lastName,
         }

         await postDataService(`web/update_profile`, new URLSearchParams(featureData))
            .then(response => {
               if (response.status === 'success') {
                  window.localStorage.setItem('userFirstName', firstName);
                  window.localStorage.setItem('userLastName', lastName);
                  setIsButton(false);
                  setIsAlertError('');
                  setIsAlertSuccess(response.message);
                  navigate("/account");
               } else {
                  setIsAlertSuccess('');
                  setIsAlertError(response.message);
               }
            });

      }
   }

   function activeTab(tab) {
      setIsActive(tab);
   }

   // Logout
   async function logout(logoutId) {
      Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, Logout'
      }).then(async (result) => {
         if (result.isConfirmed) {
            await getDataService(`web/member_logout_by_id/${logoutId}`)
               .then(response => {
                  if (response.status === 'success') {
                     Swal.fire(
                        'Logout!',
                        "Logout Successfully!",
                        'success'
                     )
                     getIndividualData();
                  } else {
                     Swal.fire(
                        'Logout!',
                        response.message,
                        'error'
                     )
                  }
               });
         }
      })

   }

   // Deactivate
   async function deactivate() {
      var statusData = {
         _id: window.localStorage.getItem('userId'),
         status: 'Deactive'
      }
      Swal.fire({
         title: 'Are you sure you want to de-activate?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, deactivate it!'
      }).then(async (result) => {
         if (result.isConfirmed) {
            await postDataService(`member_change_status`, new URLSearchParams(statusData))
               .then(response => {
                  if (response.status === 'success') {
                     Swal.fire(
                        'Deactivated!',
                        "Account Deactivated Successfully",
                        'success'
                     )
                     localStorage.clear()
                     navigate("/");
                  } else {
                     Swal.fire(
                        'Error!',
                        response.message,
                        'error'
                     )
                  }
               });
         }
      })

   }

   // Two-Factor Authentiction
   async function twoFactorAuthentiction(status) {
      if (status === 'on') {
         navigate('/enable-two-factor');
      } else {
         setShow(true);
      }
   }

   const handleSubmitAuthenticationOff = async event => {
      event.preventDefault();
      if (handleValidationAuthentication()) {
         var data = {
            token: verificationCode,
            status: false
         }
         Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, two factor authentication off it!`
         }).then(async (result) => {
            if (result.isConfirmed) {
               await postDataService(`web/enable_two_factor_authentication`, new URLSearchParams(data))
                  .then(response => {
                     if (response.status === 'success') {
                        setShow(false);
                        Swal.fire(
                           'Two Factor Authentication!',
                           "Off Successfully!",
                           'success'
                        )
                        getIndividualData();
                     } else {
                        Swal.fire(
                           'Two Factor Authentication!',
                           response.message,
                           'error'
                        )
                     }
                  });
            }
         })

      }
   }

   function upgradePlan() {
      navigate('/plans');
   }

   async function cancelPlan() {
      Swal.fire({
         title: 'Are you sure you want to cancel plan?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, cancel it!',
         cancelButtonText: 'No, Don’t Cancel!'
      }).then(async (result) => {
         if (result.isConfirmed) {
            await getDataService(`web/plan-cancel`)
               .then(response => {
                  if (response.status === 'success') {
                     Swal.fire(
                        'Cancelled!',
                        response.message,
                        'success'
                     )
                     getIndividualData();
                  } else {
                     Swal.fire(
                        'Cancelled!',
                        response.message,
                        'error'
                     )
                  }
               });
         }
      })
   }

   async function autoRenewal(endDate) {
      var data = {
         renewalStatus: 'disable'
      }
      Swal.fire({
         title: 'Are you sure?',
         text: `you have cancelled your membership, you will still have access to premium features until ${new Date(endDate).toLocaleString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
         })}`,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, cancel it!',
         cancelButtonText: 'No, Don’t Cancel!'
      }).then(async (result) => {
         if (result.isConfirmed) {
            await postDataService(`web/plan-auto-renewal`, new URLSearchParams(data))
               .then(response => {
                  if (response.status === 'success') {
                     Swal.fire(
                        `Cancelled!`,
                        'Plan cancelled successfully!',
                        'success'
                     )
                     getIndividualData();
                  } else {
                     Swal.fire(
                        `Cancelled!`,
                        response.message,
                        'error'
                     )
                  }
               });
         }
      })
   }

   async function upgradeCardInformation() {
      setShowCard(true);
   }

   // In Account.js
   const handleAfterClose = () => {
      setShowCard(false);
      getIndividualData();
   };


   return (
      <>
         <div>
            <ToastContainer closeButton={true} position="top-right" />
            <LoaderComponent active={loading} />
         </div>
         <div className="home_bg mb-5">
            <section className="account-tab">
               <div className="container">
                  <div className="row jcenter">
                     <div className="col-md-10">
                        <div className="tab-head d-flex mt-5">
                           <h4 className="mr-3">Account</h4>
                           {planData.length > 0 ?
                              planData.map((plan, i) => {
                                 return (
                                    <div className="taj mt-1">
                                       <img src={taz} />
                                       <span> Member Since {new Date(plan.date).toLocaleString('en-us', { month: 'long', year: 'numeric' })}</span>
                                    </div>
                                 )
                              })
                              : ''}
                        </div>
                        <div className="tab-wrapper">
                           {/* use data-target value as ID to show tab content */}
                           <nav className="tab-nav mt-3">
                              <button onClick={(e) => activeTab('personal')} className={isActive === 'personal' ? "is-active" : ""} data-tab-target="tab-content-1">Personal Info</button>
                              <button onClick={(e) => activeTab('Subscription')} className={isActive === 'Subscription' ? "is-active" : ""} data-tab-target="tab-content-2">Subscription</button>
                              <button onClick={(e) => activeTab('Security')} className={isActive === 'Security' ? "is-active" : ""} data-tab-target="tab-content-3">Security</button>
                           </nav>
                           <div className="tab-content">
                              {/* single item */}
                              <div className={isActive === 'personal' ? "tab-target is-active" : "tab-target"} id="tab-content-1">
                                 {isAlertError === '' ? '' :
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                       <strong>{isAlertError}</strong>
                                       <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                          "float": "right", "border": "none",
                                          "background": "#f8d7da"
                                       }}>
                                          <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                                       </button>
                                    </div>
                                 }
                                 {isAlertSuccess === '' ? '' :
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                       <strong>{isAlertSuccess}</strong>
                                       <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                          "float": "right", "border": "none",
                                          "background": "rgb(209 231 221)"
                                       }}>
                                          <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                                       </button>
                                    </div>
                                 }
                                 <form onSubmit={handleSubmit}>
                                    <div className="row">
                                       <div className="col-md-6 col-12">
                                          <div className="form">
                                             <div className="form-group">
                                                <label className="" for="uname1">First Name <span className="text-danger">*</span></label>
                                                <div className="right-inner-addon">
                                                   <input className="form-control" name="firstName" onChange={handleFirstNameChange} value={firstName} type="text" />
                                                </div>

                                                <small className="text-danger form-text">
                                                   {firstNameError}
                                                </small>
                                             </div>
                                             <div className="form-group">
                                                <label>Email <span className="text-danger">*</span></label>
                                                <div className="form_noneditable">
                                                   <div className="form_noneditable_v">{email}</div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6 col-12">
                                          <div className="form">
                                             <div className="form-group right-inner-addon">
                                                <label for="uname1">Last Name</label>
                                                <div className="right-inner-addon">
                                                   <input className="form-control" name="lastName" onChange={handleLastNameChange} value={lastName} type="text" />
                                                   <small className="text-danger form-text">
                                                      {lastNameError}
                                                   </small>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row jcenters">
                                       <div className="col-md-6">
                                       </div>
                                       <div className="col-md-6">
                                          <div className="scd_sec_btn_personal">
                                             <button disabled={!isButton} type="submit" className="btn btn_primary btn-round">Update</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                              {/* end of single item */}
                              {/* single item  */}
                              <div className={isActive === 'Subscription' ? "tab-target is-active" : "tab-target"} id="tab-content-2">
                                 {enterprisePlan ?
                                    <div className="row home_features_itmm-box-card">
                                       <div className="col-md-6">
                                          <div className="home_features_itm_cn">
                                             <h4>Enterprise Plan Enabled</h4>
                                          </div>
                                       </div>
                                    </div> :
                                    planData.length === 0 ?
                                       <div className="row">
                                          <div className="col-md-3 mt-4">
                                             <div className="home_features_itmm" onClick={(e) => { upgradePlan() }}>
                                                <div className="home_features_itm_cn">
                                                   <h6 className="text-center mb-0 font-w-5 w-100">Upgrade Plan</h6>
                                                </div>
                                             </div>
                                          </div>
                                       </div> :
                                       <>
                                          {planData.map((item, i) => {
                                             return (
                                                <div className="row home_features_itmm-box-card">
                                                   <div className="col-md-6">
                                                      <div className="home_features_itm_cn">
                                                         <h4>{item.planName} ({item.duration}) - ${amount}</h4>
                                                         <p>You will have premium access until {new Date(item.endDate).toLocaleString('en-US', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric'
                                                         })}</p>
                                                      </div>
                                                   </div>

                                                   <div className="col-md-6">
                                                      {item.planAutoRenewal ?
                                                         <div className="home_features_itmm" >
                                                            <div className="home_features_itm_cn" onClick={(e) => { autoRenewal(item.endDate) }}>
                                                               <h6 className="btn btn-theme-subscription">Cancel Plan</h6>
                                                            </div>
                                                         </div> : ''}
                                                   </div>
                                                </div>
                                             )
                                          })}

                                          <div className="row home_features_itmm-box-card mt-3">
                                             {planData.map((item, i) => {
                                                return (
                                                   <div className="col-md-6">
                                                      <div className="bill-info d-flex">
                                                         <h6>Billing Info</h6>
                                                         {/* <span><u>Add New</u></span> */}
                                                      </div>
                                                      <div className="bill-info d-flex">
                                                         <p>Your next billing date is {new Date(item.endDate).toLocaleString('en-US', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric'
                                                         })}</p>
                                                      </div>
                                                      <div className="master">
                                                         <div className="box-icon">
                                                            <img src={Mastercard_logo} />
                                                         </div>
                                                         <div className="custom-radio">
                                                            <input id="radio-2" name="radio" type="radio" checked="" />
                                                            <label for="radio-2" className="radio-label form-control">**** {cardLastDigit}</label>
                                                            <div className="check"></div>
                                                            <div className="box-icon">
                                                               <img src={Mastercard_logo} />
                                                            </div>
                                                         </div>
                                                      </div>

                                                   </div>
                                                )
                                             })}

                                             <div className="col-md-6">
                                                <div className="home_features_itmm">
                                                   <div className="home_features_itm_cn" onClick={(e) => { upgradeCardInformation() }}>
                                                      <h6 className="btn btn-theme-subscription">Update payment method</h6>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          {/* {planData.map((item, i) => {
                                          return (
                                             <div className="row home_features_itmm-box-card mt-3">

                                                <div className="col-md-6">
                                                   <div className="home_features_itm_cn">
                                                      <h4>Auto Renewal</h4>
                                                   </div>
                                                </div>
                                                <div className="col-md-6">
                                                   <div className="home_features_itmmc" >
                                                      <div className="home_features_itm_cn">
                                                         {item.planAutoRenewal ? <h6 onClick={(e) => { autoRenewal('disable') }} className="btn btn-theme-subscription-active">Disable</h6> :
                                                            <h6 onClick={(e) => { autoRenewal('enable') }} className="btn btn-theme-subscription-active">Enable</h6>}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       })} */}
                                       </>
                                 }
                              </div>

                              {/* end of single item */}
                              {/* single item */}
                              <div className={isActive === 'Security' ? "tab-target is-active" : "tab-target"} id="tab-content-3">
                                 <div className="row">
                                    <div className="col-md-5">
                                       <label>Device history</label>
                                       {loginHistory.map((item, i) => {
                                          if (item.status) {
                                             return (
                                                <div className="home_features_itmm mt-3" >
                                                   <div className="home_features_itm_cn">
                                                      <img className="mr-1" src={roundicon} />
                                                      <span className="mb-0">{item.os} · {item.browser}</span>
                                                      <span className="ml-6 history mb-3"> {new Date(item.lastLogin).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })} at {new Date(item.lastLogin).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })}</span>
                                                   </div>
                                                   {/* <div onClick={(e) => { logout(item._id) }} className="btn w-100 mt-3 btn-theme">Logout</div> */}
                                                </div>
                                             )
                                          }
                                       })}

                                       <div className="form-group mt-3">
                                          <label for="uname1">Two-Factor Authentiction</label>
                                          <select className="form-control p-relative" value={twofaEnabled} onChange={(e) => twoFactorAuthentiction(e.target.value)} aria-label="Default select example">
                                             <option value="off">Off</option>
                                             <option value="on">On</option>
                                          </select>
                                       </div>
                                       <div className="security-btn">
                                          <div className="row jcenters">
                                             <div className="col-md-6">
                                                <div className="home_features_itmm-3" onClick={(e) => navigate('/change-password')}>
                                                   <div className="home_features_itm_cn">
                                                      <h6 className="text-center mb-0 font-w-5 w-100">Change Password</h6>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="home_features_itmm-3" onClick={(e) => { deactivate() }}>
                                                   <div className="home_features_itm_cn">
                                                      <h6 className="text-center mb-0 font-w-5">Deactivate</h6>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              {/* end of single item */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section >
         </div >
         <Modal size="" className="modal-md custom-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={show} onHide={handleClose} animation={false}>
            <form onSubmit={handleSubmitAuthenticationOff}>
               <Modal.Body className="tab-content">
                  <label>Verification Code</label>
                  <input type="text" onChange={(e) => setVerificationCode(e.target.value)} className="form-control" placeholder="Type Here" />
                  <small className="text-danger form-text">
                     {verificationCodeError}
                  </small>
               </Modal.Body>
               <div className="cmodal-btns d-flex align-items-center justify-content-center">
                  <div className="next">
                     <button onClick={handleClose} className="btn btn-theme-subscription">Cancel</button>
                  </div>
                  <div className="pre">
                     <button type="submit" className="btn btn-theme-subscription">Submit</button>
                  </div>
               </div>
            </form>
         </Modal>

         <Modal size="" className="modal-md custom-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={showCard} onHide={handleCloseCard} animation={false}>
            <Modal.Body className="tab-content">
               <Elements stripe={stripePromise}>
                  <CardUpdate afterClose={handleAfterClose} />
               </Elements>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default Account;
