import React from "react";

const Privacy = () => {

  return (
    <>
      <div className="home_bg">
        <section className="home_search">
          <div className="container">
            <div className="home_features static_content_head">
              <div className="row jcenter">
                <div className="col-lg-12 col alignselfcenter">
                  <div className="home_features_itm_cn">
                    <h1 className="heading_typ4">Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row jcenter static_content">
              <div className="col-lg-10 col-md-10 col-12">
                <div className="scd_sec_content mt-5">
                  <p className="sub_heading-2 mb-3">Last Updated: January 7, 2024</p>
                  <p className="sub_heading-2 lh">Thank you for choosing MalSearch! This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By using MalSearch, you agree to the terms outlined below.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Information We Collect</h3>
                  <p className="sub_heading-2 lh">Personal Information:
                    <ul><li>We may collect personal information such as names, email addresses, and contact details for account creation or communication purposes.</li></ul>
                    Usage Data:
                    <ul><li>We automatically collect information about how you interact with our website, including your IP address, browser type, and other usage statistics.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">How We Use Your Information</h3>
                  <p className="sub_heading-2 lh">Educational Purpose:
                    <ul><li>MalSearch is designed for educational purposes only. Your data may be used to enhance our malware research efforts for educational and research purposes.</li></ul>
                    Communication:
                    <ul><li>We may use your contact information to send important updates, respond to inquiries, and provide customer support.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Information Sharing</h3>
                  <p className="sub_heading-2 lh">Third-Party Services:
                    <ul><li>We may use third-party services to analyze website performance. These services may have access to certain user data.</li></ul>
                    Legal Compliance:
                    <ul><li>We may disclose information when required by law or to protect our rights, users, or others' safety.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Security Measures</h3>
                  <p className="sub_heading-2 lh">Data Security:
                    <ul><li>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Use Restriction</h3>
                  <p className="sub_heading-2 lh">Educational Use Only:<br />
                    <ul><li>MalSearch is intended solely for educational purposes. Any use for malicious intent or unauthorized activities is strictly prohibited.</li></ul>

                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Your Choices</h3>
                  <p className="sub_heading-2 lh">Opt-Out:
                    <ul><li>Users can opt-out of receiving non-essential communications from us by following the instructions in our communications.</li></ul>
                    Access and Update:
                    <ul><li>You can access and update your personal information through your account settings.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Changes to This Privacy Policy</h3>
                  <p className="sub_heading-2 lh">We reserve the right to update this Privacy Policy. Please check the "Last Updated" date at the top for the latest version.
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">Contact Us</h3>
                  <p className="sub_heading-2 lh">If you have any questions about our Privacy Policy, please contact us at <a href="mailto:contact@malsearch.com">Contact@malsearch.com</a>. <br /><br />
                    By using MalSearch, you agree to the terms outlined in this Privacy Policy.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};


export default Privacy;
