import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import visa_1 from "../assets/img/visa (1).svg";
import american from "../assets/img/american_new.png";
import master from "../assets/img/master.svg";
import { postDataService } from '../userService';
import Swal from "sweetalert2";
import LoaderComponent from './LoaderComponent';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentForm = (planDetail) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cardName, setCardName] = useState();
    const [email, setEmail] = useState(window.localStorage.getItem('email'));
    const [country, setCountry] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [planId, setPlanId] = useState();

    const [cardNameError, setCardNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [countryError, setCountryError] = useState('');


    const [clientSecret, setClientSecret] = useState('');
    const [paymentError, setPaymentError] = useState(null);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        setTotalAmount(planDetail.amount);
        setPlanId(planDetail.id);
    }, [totalAmount, planId]);

    useEffect(() => {
        // Load countries from JSON file
        fetch('/countries.json')
            .then((response) => response.json())
            .then((data) => setCountries(data))
            .catch((error) => console.error('Error loading countries:', error));
    }, []);

    // Validation
    const handleValidation = (event) => {
        let formIsValid = false;
        if (!cardName) {
            setCardNameError("Card name is required!");
            return false;
        } else {
            setCardNameError("");
            formIsValid = true;
        }
        if (!email) {
            setEmailError("Email is required!");
            return false;
        } else {
            setEmailError("");
            formIsValid = true;
        }
        if (!country) {
            setCountryError("Country is required!");
            return false;
        } else {
            setCountryError("");
            formIsValid = true;
        }

        return formIsValid;
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (handleValidation()) {

                setLoading(true);

                const cardElement = elements.getElement(CardElement);

                const paymentMethodReq = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: cardName,
                        email: email,
                    },
                });

                if (paymentMethodReq.error) {
                    setPaymentError(paymentMethodReq.error.message);
                    setLoading(false);
                    return;
                }

                const paymentMethodId = paymentMethodReq.paymentMethod.id;

                var data = {
                    amount: planDetail.amount,
                    name: cardName,
                    email: email,
                    planId: planDetail.id,
                    paymentMethodId: paymentMethodId,
                }
                setLoading(true);
                await postDataService(`create-payment-intent`, new URLSearchParams(data))
                    .then(async response => {
                        setLoading(false);
                        if (response.status === 'success') {
                            Swal.fire(
                                'Plan Upgraded!',
                                "Plan Purchased Successfully",
                                'success'
                            )
                            navigate("/account");
                        } else {
                            toast.dismiss();
                            toast.error(response.message);
                            setLoading(false);
                        }
                    });
                setLoading(false);
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error);
        }

    };

    return (
        <>
            <div>
                <ToastContainer closeButton={true} position="top-right" />
                <LoaderComponent active={loading} />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="home_features_itmm">
                    <div className="home_features_itm_cn">
                        <h5>Pay With</h5>
                        <div className="pay-section d-flex">
                            <div className="home_features_itmm-box ">
                                <img src={visa_1} />
                            </div>
                            <div className="home_features_itmm-box">
                                <img src={american} />
                            </div>
                            <div className="home_features_itmm-box">
                                <img src={master} />
                            </div>
                        </div>
                        {paymentError && <div className='text-danger text-center mt-3'>{paymentError}</div>}
                        <div className="form-group mt-3 ">
                            <label for="uname1">Name on card</label>
                            <input className="form-control" value={cardName} onChange={(e) => setCardName(e.target.value)} type="text" placeholder="Enter card name" />
                            <small className="text-danger form-text">
                                {cardNameError}
                            </small>
                        </div>
                        <div className="form-group extra-icon with-btn">
                            <label>Card Information</label>
                            <CardElement className="CardField" options={{
                                style: {
                                    base: {
                                        color: 'white', // Text color
                                        '::placeholder': {
                                            color: 'white', // Placeholder color
                                        },
                                    },
                                },
                            }} />
                        </div>
                        <hr />
                        <h6>Contact Information</h6>
                        <div className="form-group with-btn">
                            <label>Email</label>
                            <i className="fa fa-envelope card-icon"></i>
                            <input className="form-control px-5" readOnly value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email address" />
                            <small className="text-danger form-text">
                                {emailError}
                            </small>
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <select onChange={(e) => setCountry(e.target.value)} className="form-control">
                                <option>Select Country</option>
                                {countries.map((country, i) => {
                                    return (
                                        <option value={country.name}>{country.name}</option>
                                    )
                                })}
                            </select>
                            <small className="text-danger form-text">
                                {countryError}
                            </small>
                        </div>
                        <button className="btn btn-primery btn-theme-lg" type="submit" disabled={!stripe}>
                            Pay Now
                        </button>
                    </div>
                </div>
            </form>
            <div>
            </div>
        </>
    );
};

export default PaymentForm;
