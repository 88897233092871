import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import visa_1 from "../assets/img/visa (1).svg";
import americanExpress from "../assets/img/american_new.png";
import master from "../assets/img/master.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postDataService } from '../userService';
import LoaderComponent from './LoaderComponent';

const CardUpdate = ({ afterClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [cardName, setCardName] = useState();
    const [cardNameError, setCardNameError] = useState('');
    const [paymentError, setPaymentError] = useState(null);

    const handleCloseCard = () => {
        afterClose();
    };

    // Validation
    const handleValidation = (event) => {
        let formIsValid = false;
        if (!cardName) {
            setCardNameError("Card name is required!");
            return false;
        } else {
            setCardNameError("");
            formIsValid = true;
        }
        return formIsValid;
    };

    const handleUpdateCard = async (e) => {
        e.preventDefault();
        try {
            if (handleValidation()) {
                const cardElement = elements.getElement(CardElement);
                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

                if (error) {
                    console.error(error.message);
                    setPaymentError(error.message);
                    return;
                }

                var data = {
                    paymentMethodId: paymentMethod.id
                };

                toast.dismiss();
                setLoading(true);

                await postDataService(`web/update_card`, new URLSearchParams(data))
                    .then(response => {
                        setLoading(false);
                        if (response.status === 'success') {
                            afterClose();
                            toast.success(response.message);
                        } else {
                            toast.error(response.message);
                        }
                    });
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <div>
                <ToastContainer closeButton={true} position="top-right" />
                <LoaderComponent active={loading} />
            </div>
            <div>
                <form onSubmit={handleUpdateCard}>
                    <div className="text-center">
                        <h5>Update Payment Method</h5>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod tempor incididunt</p> */}
                    </div>
                    <div className="home_features_itm_cn mt-5">
                        <div className="pay-section d-flex">
                            <div className="home_features_itmm-box ">
                                <img src={visa_1} />
                            </div>
                            <div className="home_features_itmm-box">
                                <img src={americanExpress} />
                            </div>
                            <div className="home_features_itmm-box">
                                <img src={master} />
                            </div>
                        </div>
                        {paymentError && <div className='text-danger text-center mt-3'>{paymentError}</div>}
                        <div className="form-group mt-3 ">
                            <label for="uname1">Card Holder Name</label>
                            <input className="form-control" value={cardName} onChange={(e) => setCardName(e.target.value)} type="text" placeholder="Enter card holder name" />
                            <small className="text-danger form-text">
                                {cardNameError}
                            </small>
                        </div>
                        <div className="form-group extra-icon with-btn">
                            <label>Card Information</label>
                            <CardElement className="CardField" options={{
                                style: {
                                    base: {
                                        color: 'white', // Text color
                                        '::placeholder': {
                                            color: 'white', // Placeholder color
                                        },
                                    },
                                },
                            }} />
                        </div>
                    </div>
                    <div className="cmodal-btns d-flex align-items-center justify-content-center">
                        <div className="next">
                            <button onClick={handleCloseCard} className="btn btn-theme-subscription">Cancel</button>
                        </div>
                        <div className="pre">
                            <button type="submit" className="btn btn-theme-subscription">Next</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CardUpdate;