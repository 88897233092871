import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo_new1.png";
import menu from "../assets/img/menu.svg";
import cross from "../assets/img/cross.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getDataService } from "../userService";

export default () => {
    const navigate = useNavigate();
    const [isNav, setIsNav] = useState(false);
    const [logged, setLogged] = useState(false);
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)

        const firstName = window.localStorage.getItem('userFirstName');
        const lastName = window.localStorage.getItem('userLastName');

        setFName(firstName);
        setLName(lastName === 'undefined' ? '' : lastName);

        const token = window.localStorage.getItem('accessToken');
        const permission = window.localStorage.getItem('permission');


        if (token !== null && permission === 'Member') {
            setLogged(true);
        } else {
            setLogged(false);
        }

        if (isNav) {
            document.body.classList.add('menuactive');
        } else {
            document.body.classList.remove('menuactive');
        }
    }, [isNav, window.location.href, logged, window.localStorage.getItem('userFirstName'), window.localStorage.getItem('userLastName')])

    const handleLogout = async e => {
        await getDataService(`self_logout`)
            .then(response => {
                if (response.status === 'success') {
                    localStorage.clear()
                    navigate("/");
                } else {
                    console.log(response.message)
                }
    })
}

return (
    <>
        <header className="header_sec">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" onClick={() => setIsNav(isNav => !isNav)} to="/"><img src={logo} /></Link>
                    <button className="navbar-toggler" onClick={() => setIsNav(isNav => !isNav)} type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <img src={menu} />
                    </button>
                    <div className="cross_ic_itm" onClick={() => setIsNav(isNav => !isNav)}>
                        <a className="cross_ic"><img src={cross} /></a>
                    </div>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/">Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/plans">Pricing</Link></li>
                            <li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/project">Search</Link></li>
                            <li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/about-us">About</Link></li>
                            <li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/contact-us">Contact Us</Link></li>
                        </ul>
                        <ul className="navbar-nav navbar_right">
                            {logged ? <div className="navbar_profile dropdown">
                                <a href="#" className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="navbar_profile_img"><h5>{fname.split('')[0]}{lname.split('')[0] === undefined ? '' : lname.split('')[0]}</h5></div>
                                    <div className="navbar_profile_con">
                                        <p className="navbar_profile_nm">{fname} {lname}</p>
                                    </div>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" to="/account">Account Management</Link></li>
                                    <li><Link className="dropdown-item" to="/bookmarked">Starred Projects and Code</Link></li>
                                    <li onClick={handleLogout}><a className="dropdown-item" href="#">Logout</a></li>
                                </ul>
                            </div> : <><li className="nav-item"><Link className="nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/login"><span>Log In</span></Link></li>
                                <li className="nav-item"><Link className="btn_border nav-link" onClick={() => setIsNav(isNav => !isNav)} to="/signup"><span>Sign Up</span></Link></li></>}


                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </>
);
};
