import React, { useEffect, useState } from "react";
import bg from "../assets/img/bg.jpg";
import search from "../assets/img/search.svg";
import main_img from "../assets/img/main_img.png";
import HomeImageFirst from "../assets/img/HomeImageFirst.png";
import Server1 from "../assets/img/Server1.png";
import Firewall1 from "../assets/img/Firewall1.png";
import AntiVirus1 from "../assets/img/AntiVirus1.png";
import group_4 from "../assets/img/Group 1171276459.svg";
import srde from "../assets/img/srdenew.png";
import { Link, useNavigate } from "react-router-dom";
import { getDataService } from "../userService";

export default () => {

    const navigate = useNavigate();
    const [filter, setFilter] = useState();
    const [filterError, setFilterError] = useState('');

    useEffect(() => {
        const token = window.localStorage.getItem('accessToken');
        if (token) {
            checkTokenValidity();
        }

    }, []); // Empty dependency array means this effect runs once after the initial render

    const checkTokenValidity = async () => {
        try {
            await getDataService(`web/check_token`)
                .then(response => {
                    if (response.status === 'success') {

                    } else {
                        console.error('Error checking token validity:', response.message);
                    }
                });
        } catch (error) {
            // Handle network errors or other issues
            console.error('Error checking token validity:', error);
        }
    };

    // Validation
    const handleValidation = (event) => {
        let formIsValid = false;
        if (!filter || filter.trim() === '') {
            setFilterError("Please Enter a keyword!");
            return false;
        } else if (filter.length === 1 || filter.length === 2) {
            setFilterError('Your search is too short, please try a search longer than 2 characters');
            return false;
        } else {
            setFilterError("");
            formIsValid = true;
        }

        return formIsValid;
    };

    const filterHandle = e => {
        if (e.key === "Enter" || e.type === "click") {
            if (handleValidation()) {
                // const sanitizedInput = DOMPurify.sanitize(filter);
                const newState = {
                    filter: filter,
                    currentPage: 1,
                };
                navigate('/project', { state: newState });
            }
        }
    };

    return (
        <>
            <div class="main_sec">
                <div class="home_bg" style={{ "backgroundImage": `url(${bg})`, "backgroundRepeat": "no-repeat", "backgroundSize": "cover" }}>

                    <section class="home_search">
                        <div class="container">
                            <div class="home_search_in">
                                <h1 class="heading_typ4"><img className="homeFirstImage" src={HomeImageFirst} /></h1>
                                <p>The world’s largest Malware search engine with over 10,000,000 lines of Malware Source code!</p>

                                <div class="search_s">
                                    <input type="text" value={filter}
                                        onKeyPress={filterHandle} onChange={(e) => setFilter(e.target.value)} placeholder="Search Malware Code and Projects …" class="form-control" />
                                    <button class="btn btn_secondary" onClick={filterHandle}><img src={search} /></button>

                                </div>
                                <small style={{ "marginLeft": "238px" }} className="text-danger form-text">
                                    {filterError}
                                </small>
                                <div class="search_s_img"><img src={main_img} /></div>
                            </div>
                            <div class="home_features">
                                <h2 class="heading_typ4">MalSearch Features</h2>
                                <p class="sub_heading">Explore the largest collection of Malware source code repositories through MalSearch</p>

                                <div class="home_features_itms">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6">
                                            <div class="home_features_itm">
                                                <div class="home_features_itm_ic"><img src={Server1} /></div>
                                                <div class="home_features_itm_cn">
                                                    <h4>50+ Programming Languages</h4>
                                                    <p>Explore Malware Code in 50+ Languages for Comprehensive Analysis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <div class="home_features_itm">
                                                <div class="home_features_itm_ic"><img src={Firewall1} /></div>
                                                <div class="home_features_itm_cn">
                                                    <h4>Search for Any OS</h4>
                                                    <p>Effortlessly Search Malware Projects Tailored to Any Operating System.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <div class="home_features_itm">
                                                <div class="home_features_itm_ic"><img src={AntiVirus1} /></div>
                                                <div class="home_features_itm_cn">
                                                    <h4>Easily Filter and Analyze Code</h4>
                                                    <p>Effortlessly Filter and Analyze Malware Code for In-Depth Understanding.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <div class="home_features_itm">
                                                <div class="home_features_itm_ic"><img src={group_4} /></div>
                                                <div class="home_features_itm_cn">
                                                    <h4>Ever-Growing Repositories</h4>
                                                    <p>Dive into a Continuously Growing Pool of Malware Repositories.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="scd_sec">
                    <div class="row m-0 align-items-center">
                        <div class="col-md-5 p-0">
                            <div class="scd_sec_in">
                                <div class="scd_sec_con">
                                    <h3 class="heading_type3">Malware Source Code Search Engine</h3>
                                    <p class="sub_heading">Effortlessly Filter, Analyze, Download, Copy, and Bookmark Top Malware Projects and Source Code.</p>
                                    <div class="scd_sec_btn"><Link to="/project" class="btn btn_primary btn-round">Get Started</Link></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 p-0">
                            <div class="scd_sec_img"><img src={srde} /></div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};
