// import React from 'react';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// const CodeViewer = ({ code }) => {
//     const codeString = '(num) => num + 1';
//   return (
//     <SyntaxHighlighter id="contentToCopy" language="javascript" style={a11yDark} showLineNumbers>
//       {code}
//     </SyntaxHighlighter>
//   );
// };

// export default CodeViewer;

// Done point
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import up_arrow from "../assets/img/up-arrow.png";
import down_arrow from "../assets/img/down-arrow.png";

const CodeViewer = ({ isBinary, code, search }) => {
  const codeRef = useRef(null);
  const [searchFilter, setSearchFilter] = useState(search);
  const [matchingLines, setMatchingLines] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollTrigger, setScrollTrigger] = useState(0);

  // Use this useEffect to get the reference to the SyntaxHighlighter component
  useEffect(() => {
    codeRef.current = document.getElementById('codeViewerSyntaxHighlighter');
  }, []);

  // Triggered when the code prop changes
  useEffect(() => {
    // Update the search filter when code changes
    handleSearchChangeAuto({ key: 'Enter' });
    codeRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [code, search]);

  useEffect(() => {
    if (!searchFilter.trim()) {
      return;
    }
    if (codeRef.current) {
      // Example: Scroll to line with id="line-96"
      const line96Element = codeRef.current.querySelector(`#line-${matchingLines[0]}`);
      if (line96Element) {
        line96Element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [matchingLines]);

  // Use scrollTrigger as a dependency in useEffect to trigger the scroll operation
  useEffect(() => {
    if (scrollTrigger > 0) {
      const lineElement = codeRef.current.querySelector(
        `#line-${matchingLines[activeIndex]}`
      );

      if (lineElement) {
        lineElement.scrollIntoView({ block: 'center' });
      }

      // Reset the scrollTrigger
      setScrollTrigger(0);
    }
  }, [scrollTrigger, activeIndex]);

  // Use useEffect to trigger the scroll operation when activeIndex changes
  useEffect(() => {
    scrollToMatchingLine(activeIndex);
  }, [activeIndex]);

  // ...

  // Inside scrollToMatchingLine function
  async function scrollToMatchingLine(index) {
    if (index >= 0 && index < matchingLines.length) {
      const lineElement = codeRef.current.querySelector(
        `#line-${matchingLines[index]}`
      );

      if (lineElement) {
        console.log(lineElement);
        // Use Promise to wait for the next render cycle
        await new Promise((resolve) => setTimeout(resolve,));
        lineElement.scrollIntoView({ block: 'center' });
      }
    }
  }

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
    setActiveIndex(0);
    setMatchingLines([]);
  };

  const handleSearchChangeAuto = (event) => {
    setActiveIndex(0);
    setMatchingLines([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && matchingLines.length > 0) {
      setActiveIndex((prevIndex) =>
        prevIndex < matchingLines.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === 'ArrowUp') {
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : matchingLines.length - 1
      );
    } else if (event.key === 'ArrowDown') {
      setActiveIndex((prevIndex) =>
        prevIndex < matchingLines.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  const highlightLines = (lineNumber) => {
    if (!searchFilter.trim()) {
      // If the search term is blank, do not highlight any lines
      return {
        id: `line-${lineNumber}`,
        style: {
          background: 'inherit',
        },
      };
    }

    // Check if the line contains the search term using a case-insensitive match
    const lineContent = code.split('\n')[lineNumber - 1];
    const escapedSearchTerm = searchFilter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchTermRegex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const containsSearchTerm = searchTermRegex.test(lineContent);

    if (containsSearchTerm) {
      setMatchingLines((prevLines) => {
        if (!prevLines.includes(lineNumber)) {
          return [...prevLines, lineNumber];
        }
        return prevLines;
      });
    }
    return {
      id: `line-${lineNumber}`,
      style: {
        background: containsSearchTerm ? '#8d65e8' : 'inherit',
      },
    };
  };

  return (
    <div>
      <div className='row' style={{ "marginTop": "-45px" }}>
        <div className='col-sm-6'></div>
        <div className='col-sm-6'>
          <div className='codeSearch'>
            <span className='code_result'>Total Results: {matchingLines.length} </span>
            <input
              type="text"
              className='form-control form-control-code-search'
              placeholder="Search..."
              value={searchFilter}
              onChange={handleSearchChange}
            />
            <span className='code_step'>{(matchingLines.length === 0 ? 0 : activeIndex + 1) + '/' + matchingLines.length}</span>
            <button className='btn btn_secondary_left' onClick={() => handleKeyDown({ key: 'ArrowUp' })}>
              <img src={up_arrow} />
            </button>
            <button className='btn btn_secondary_right' onClick={() => handleKeyDown({ key: 'ArrowDown' })}>
              <img src={down_arrow} />
            </button>
          </div>
        </div>


      </div>
      <SyntaxHighlighter
        id="codeViewerSyntaxHighlighter"
        language="javascript"
        style={a11yDark}
        showLineNumbers
        wrapLines={true}
        lineProps={(lineNumber) => highlightLines(lineNumber)}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeViewer;


