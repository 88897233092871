import React, { useState } from "react";
import emailsvg from "../assets/img/email.svg";
import lock from "../assets/img/lock.svg";
import { Link, useNavigate } from "react-router-dom";
import { postDataService } from "../userService";
import Swal from "sweetalert2";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberme, setRememberme] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!email) {
      setEmailError("Email is required!");
      return false;
    } else {
      setEmailError("");
      formIsValid = true;
    }
    if (!password) {
      setPasswordError("Password is required!");
      return false;
    } else {
      setPasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      var user = {
        email: email,
        password: password,
        rememberme: rememberme
      }
      await postDataService('login', new URLSearchParams(user))
        .then(async response => {
          if (response.status === 'success') {

            if (response.user.twofaEnabled) {
              localStorage.setItem(
                "loginStep2VerificationToken",
                response.user.loginStep2VerificationToken
              );
              navigate("/login-with-otp");
            } else {
              localStorage.setItem('accessToken', response.user.token);
              localStorage.setItem('userFirstName', response.user.firstName);
              localStorage.setItem('userLastName', response.user.lastName === undefined ? '' : response.user.lastName);
              localStorage.setItem('userId', response.user._id);
              localStorage.setItem('email', response.user.email);
              localStorage.setItem('userImage', response.user.image);
              localStorage.setItem('permission', response.user.permission);
              setIsAlertError('');
              setIsAlertSuccess(response.message);
              // if (response.user.isLogin) {
              //   const result = await Swal.fire({
              //     title: 'You’ve been signed in from a different device',
              //     icon: 'warning',
              //     showCancelButton: false,
              //     confirmButtonColor: '#3085d6',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: `OK`
              //   });
              //   if (result.isConfirmed) {
              //     // Check redirect search page or not
              //     if (window.localStorage.getItem('page')) {
              //       navigate('/project', { state: window.localStorage.getItem('filter') });
              //     } else {
              //       if (response.user.planStatus) {
              //         navigate("/");
              //       } else {
              //         navigate("/plans");
              //       }
              //     }
              //   } else {
              //     if (window.localStorage.getItem('page')) {
              //       navigate('/project', { state: window.localStorage.getItem('filter') });
              //     } else {
              //       if (response.user.planStatus) {
              //         navigate("/");
              //       } else {
              //         navigate("/plans");
              //       }
              //     }
              //   }
              // } else {
              if (window.localStorage.getItem('page')) {
                const newState = {
                  filter: window.localStorage.getItem('filter'),
                  currentPage: window.localStorage.getItem('currentPage'),
                  typeId: window.localStorage.getItem('typeId'),
                  technologyId: window.localStorage.getItem('technologyId'),
                  categoryId: window.localStorage.getItem('categoryId'),
                  osId: window.localStorage.getItem('osId'),
                  sortBy: window.localStorage.getItem('sortBy')
                };
                navigate('/project', { state: newState });
              } else {
                if (response.user.planStatus) {
                  navigate("/");
                } else {
                  navigate("/plans");
                }
              }
              // }

            }


          } else {
            setIsAlertSuccess('');
            setIsAlertError(response.message);
          }
        });
    }
  }

  return (
    <>
      <div class="main_sec">
        <div class="close-btn">
        </div>
        <div class="st_1">
          <div class="st_1_in">
            <h2 class="heading_typ1">Login to your account</h2>
            {isAlertError === '' ? '' :
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isAlertError}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "#f8d7da"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                </button>
              </div>
            }
            {isAlertSuccess === '' ? '' :
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{isAlertSuccess}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "rgb(209 231 221)"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                </button>
              </div>
            }
            <form>
              {window.localStorage.getItem('tokenStatus') ? <p className="text-danger">Please Log In Again</p> : ''}
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email Address</label>
                    <div class="form-group-item">
                      <div class="form-group-img"><img src={emailsvg} /></div>
                      <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" class="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {emailError}
                    </small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Password</label>
                    <div class="form-group-item">
                      <div class="form-group-img"><img src={lock} /></div>
                      <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" class="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {passwordError}
                    </small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <label class="custom_checkbox"><input type="checkbox" onClick={() => setRememberme(rememberme => !rememberme)} /><span class="checkmark"></span>Remember me</label>
                    </div>
                    <div className="col-sm-6">
                      <div class="form-group text-end">
                        <Link to="/forgot-password">Forgot password?</Link>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <button class="btn btn_primary" type="submit" onClick={handleSubmit}>Login</button>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <p>Don’t have an account? <Link to="/signup">Signup</Link></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default Login;