import React, { useState } from "react";
import about_img from "../assets/img/about_sssss.png";
import frame from "../assets/img/Frame.svg";
import frame1 from "../assets/img/Frame-1.svg";
import frame2 from "../assets/img/Frame-2.svg";
import star1 from "../assets/img/star 1.svg";
import review1 from "../assets/img/review_1.png";
import review2 from "../assets/img/review_2.png";
import review3 from "../assets/img/review_3.png";
import Frame4 from "../assets/img/Frame4.svg";
import quotationmark2 from "../assets/img/quotation-mark 2.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(0);
  const [oldIsOpen, setOldIsOpen] = useState(null);

  function dropdownOpen(number) {
    if (number === oldIsOpen) {
      setIsOpen(0);
      setOldIsOpen(null);
    } else {
      setIsOpen(number);
      setOldIsOpen(number);
    }
  }

  return (
    <>
      <div class="home_bg">
        <section class="home_search">
          <div class="container">
            <div class="home_features static_content_head">
              <div class="row jcenter">
                <div class="col-lg-12 col alignselfcenter">
                  <div class="home_features_itm_cn">
                    <h1 class="heading_typ4">About Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="about_sec">
        <div class="container">
          <div class="about_sec_in">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="about_con">
                  <div class="scd_sec_con">
                    <h3 class="heading_type3">Our Mission</h3>
                    <p class="sub_heading">MalSearch’s target mission is to be the centralized place for all high quality malware source code on the internet. Additionally, all malware that is added onto MalSearch is manually reviewed and categorized by one of our seasoned Malware Developers.</p>
    <p class="sub_heading">This enables Malware Developers and enthusiasts to seamlessly filter, search, download or copy Malware source code. We charge a small monthly fee in order to keep our servers running and cater to this niche audience of malware enthusiasts.</p>
    
                    <div class="scd_sec_btn"><Link to="/project" class="btn btn_primary btn-round">Get Started</Link></div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="about_img">
                  <img src={about_img} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="works">
        <div class="container">
          <div class="home_features">
            <h2 class="heading_typ4">How We Work</h2>
            <p class="sub_heading">Malsearch aims to constantly expand our repository of Malware source code. Our team of malware developers reviews the code and categorizes it by Programming Language, Operating System and Malware Type to make your experience in finding quality malware simple and easy.</p>
            <div class="home_features_itms">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="home_features_itm">
                    <div class="home_features_itm_ic">
                      <img class="itm-frame" src={frame} />
                    </div>
                    <div class="home_features_itm_cn">
                      <h4>50+ Programming Languages</h4>
                      <p>Explore Malware Code in 50+ Languages for Comprehensive Analysis</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="home_features_itm">
                    <div class="home_features_itm_ic">
                      <img class="itm-frame" src={frame1} />
                    </div>
                    <div class="home_features_itm_cn">
                      <h4>Search for Any OS</h4>
                      <p>Effortlessly Search Malware Projects Tailored to Any Operating System.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="home_features_itm">
                    <div class="home_features_itm_ic">
                      <img class="itm-frame" src={frame2} />
                    </div>
                    <div class="home_features_itm_cn">
                      <h4>Easily Filter and Analyze Code</h4>
                      <p>Effortlessly Filter and Analyze Malware Code for In-Depth Understanding.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="home_features_itm">
                    <div class="home_features_itm_ic">
                      <img class="itm-frame" src={Frame4} />
                    </div>
                    <div class="home_features_itm_cn">
                      <h4>Ever-Growing Repositories</h4>
                      <p>Dive into a Continuously Growing Pool of Malware Repositories.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="scd_sec mt-5 mb-5">
        <div class="container">
          <div class="row jcenter">
            <div class="col-md-4 align-items-center">
              <div class="scd_sec_con-2">
                <h3 class="heading_type3">What Our Users Say</h3>
                <p class="sub_heading-2">Hear the Voices of Satisfied Clients - Their Experience Showcases Our Commitment to Excellence.</p>
              </div>
            </div>
            <div class="col-md-8">
              <div class="screenshot_slider-2 ">
                <OwlCarousel className='owl-theme' loop margin={20} items={1} nav>
                  <div class="item item-section-1 bg-sec-2 mr-3">
                    <div class="rating">
                      <ul class="list-unstyled">
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                      </ul>
                    </div>
                    <div class="profile-content mt-3 mb-3">
                      <p class="chat-text text-left">“MalSearch is my go-to for staying updated on the latest Malware Projects and Source code. The robust filtering options by OS, programming language, and malware category make it highly recommended for staying informed and finding malware projects.”
                      </p>
                    </div>
                    <div class="d-flex mt-2">
                      <div class="profile-img-chat mr-1">
                        <img class="img-fluid" src={review1} />
                      </div>
                      <div class="chat-profile mt-3">
                        <h5 class="text-white mb-0">@NotZvxn</h5>
                        <span>Premium User</span>
                        <div class="bg-icon"><img src={quotationmark2} /></div>
                      </div>
                    </div>
                  </div>
                  <div class="item item-section-1">
                    <div class="rating">
                      <ul class="list-unstyled">
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                      </ul>
                    </div>
                    <div class="profile-content mt-3 mb-3">
                      <p class="chat-text text-left">“MalSearch is an indispensable tool for navigating the intricate world of malware. Its rich repository, intuitive interface, and powerful filtering options by OS, programming language, and malware category make it a standout. The ability to seamlessly analyze, download, and bookmark projects adds another layer of convenience. Highly impressed and recommended for any cybersecurity professional.”
                      </p>
                    </div>
                    <div class="d-flex mt-2">
                      <div class="profile-img-chat mr-1">
                        <img class="img-fluid" src={review2} />
                      </div>
                      <div class="chat-profile mt-3">
                        <h5 class="text-white mb-0">@a_daudjee</h5>
                        <span>Premium User</span>
                        <div class="bg-icon"><img src={quotationmark2} /></div>
                      </div>
                    </div>
                  </div>
                  <div class="item item-section-1 bg-sec-2 mr-3">
                    <div class="rating">
                      <ul class="list-unstyled">
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                        <li><img class="img-fluid" src={star1} /></li>
                      </ul>
                    </div>
                    <div class="profile-content mt-3 mb-3">
                      <p class="chat-text text-left">“MalSearch revolutionized my malware analysis. Expansive code, user-friendly features, and comprehensive filtering for cybersecurity professionals.”
                      </p>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="profile-img-chat mr-1">
                        <img class="img-fluid" src={review3} />
                      </div>
                      <div class="chat-profile mt-3">
                        <h5 class="text-white mb-0">@4straSec</h5>
                        <span>Premium User</span>
                        <div class="bg-icon"><img src={quotationmark2} /></div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="scd_sec scd_sec_none mt-5">
        <div class="container">
          <div class="row jcenter">
            <div class="col-md-8">
              <h2 class="heading_typ4">Frequently asked questions</h2>
              {/* <p class="sub_heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.</p> */}
              <div class="faq-container mt-5">
                <div class="faq-item" onClick={() => dropdownOpen(1)}>
                  <div class="faq-question">What makes Malsearch different from malware source code repositories in Github?</div>
                  <div class="icon-container"><i class={isOpen === 1 ? "fas fa-chevron-right active" : "fas fa-chevron-right"}></i></div>
                </div>
                <div class={isOpen === 1 ? "faq-answer active" : "faq-answer"} style={isOpen === 1 ? { "max-height": "350px" } : { "max-height": "0px" }}>
                  <p>Our platform aims to provide additional benefits for malware developers and enthusiasts:</p>
                  <p>Exclusivity - We scour the internet, including the dark and deep web, to provide the latest and highest-quality malware, including exclusive content not available on GitHub.</p>
                  <p>Centralized Platform - We offer a single, unified platform for malware source code, including exclusive source code not available on GitHub or behind paywalls.</p>
                  <p>Optimized Search - Our search function is tailored to malware repositories and source code, ensuring precise results without the noise of unrelated code.</p>
                  <p>Categorization - Our team has manually categorized malware repositories and source code, making it easy to find specific Types of Malware, Programming Languages or Operating System.</p>
                  <p>Exclusive Perks and Deals - Enjoy special offers on malware-related platforms to enhance your development journey.</p>
                  <p>Community Access - Join our private Discord community to discuss and request malware source code and projects with fellow professionals.</p>
  </div>
  
                <div class="faq-item" onClick={() => dropdownOpen(2)}>
                  <div class="faq-question">How Can I Search For Malware Projects on MalSearch?</div>
                  <div class="icon-container"><i class={isOpen === 2 ? "fas fa-chevron-right active" : "fas fa-chevron-right"}></i></div>
                </div>
                <div class={isOpen === 2 ? "faq-answer active" : "faq-answer"} style={isOpen === 2 ? { "max-height": "145px" } : { "max-height": "0px" }}>
                  <p>To access the comprehensive search functionality for malware projects on MalSearch, a premium account is required. Upon subscription to the premium account, you gain the ability to search an extensive database of malware projects and source code. </p>
                  <p className="mt-3">Additionally, the premium account provides full access to all features available on our platform.</p>
                </div>
                <div class="faq-item" onClick={() => dropdownOpen(3)}>
                  <div class="faq-question">Is MalSearch Free?</div>
                  <div class="icon-container"><i class={isOpen === 3 ? "fas fa-chevron-right active" : "fas fa-chevron-right"}></i></div>
                </div>
                <div class={isOpen === 3 ? "faq-answer active" : "faq-answer"} style={isOpen === 3 ? { "max-height": "64px" } : { "max-height": "0px" }}>
                  <p>While sign-up is complimentary, accessing the full spectrum of MalSearch's features requires a subscription.</p>
                </div>
                <div class="faq-item" onClick={() => dropdownOpen(4)}>
                  <div class="faq-question">Is it Safe to Explore Malware Code on MalSearch?</div>
                  <div class="icon-container"><i class={isOpen === 4 ? "fas fa-chevron-right active" : "fas fa-chevron-right"}></i></div>
                </div>
                <div class={isOpen === 4 ? "faq-answer active" : "faq-answer"} style={isOpen === 4 ? { "max-height": "64px" } : { "max-height": "0px" }}>
                  <p>Certainly, browsing malware code within our editors is secure. However, exercise caution when downloading, and refrain from executing downloaded malware.</p>
                </div>
                <div class="faq-item" onClick={() => dropdownOpen(5)}>
                  <div class="faq-question">How often is MalSearch updated with new projects?</div>
                  <div class="icon-container"><i class={isOpen === 5 ? "fas fa-chevron-right active" : "fas fa-chevron-right"}></i></div>
                </div>
                <div class={isOpen === 5 ? "faq-answer active" : "faq-answer"} style={isOpen === 5 ? { "max-height": "64px" } : { "max-height": "0px" }}>
                  <p>MalSearch is updated frequently with the latest malware source code and projects!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className=" col-12">
            <div className="sub_heading-2 mb-3 text-center">For any additional questions, please contact <a href="mailto:contact@malsearch.com">Contact@malsearch.com</a></div>
          </div>
        </div>
      </section>

    </>
  );
};

export default AboutUs;
