import React from "react";

const Terms = () => {

  return (
    <>
      <div className="home_bg">
        <section className="home_search">
          <div className="container">
            <div className="home_features static_content_head">
              <div className="row jcenter">
                <div className="col-lg-12 col alignselfcenter">
                  <div className="home_features_itm_cn">
                    <h1 className="heading_typ4">Terms of Service</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row jcenter static_content">
              <div className="col-lg-10 col-md-10 col-12">
                <div className="scd_sec_content mt-5">
                  <p className="sub_heading-2 mb-3">Last Updated: January 7, 2024</p>
                  <p className="sub_heading-2 lh">Welcome to MalSearch! By using this website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our services.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">1. Acceptance of Terms</h3>
                  <p className="sub_heading-2 lh">By accessing or using MalSearch, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not use our services.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">2. Educational Purpose</h3>
                  <p className="sub_heading-2 lh">MalSearch is provided for educational purposes only. Users are prohibited from engaging in any activity that violates applicable laws or the terms outlined herein.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">3. Prohibited Activities</h3>
                  <p className="sub_heading-2 lh">Users are expressly prohibited from:
                    <ul><li>Using MalSearch for malicious purposes.</li></ul>
                    <ul><li>Attempting to exploit vulnerabilities for unauthorized access.</li></ul>
                    <ul><li>Engaging in any form of hacking, data theft, or unauthorized access.</li></ul>
                  </p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">4. User Responsibilities</h3>
                  <p className="sub_heading-2 lh">Users are responsible for ensuring that their use of MalSearch complies with all applicable laws and regulations. Any misuse may result in termination of access.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">5. No Warranty</h3>
                  <p className="sub_heading-2 lh">MalSearch is provided "as is" without any warranty. We do not guarantee the accuracy, completeness, or suitability of the information and materials found on the website.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">6. Limitation of Liability</h3>
                  <p className="sub_heading-2 lh">We shall not be held liable for any damages arising out of the use or inability to use MalSearch. This includes but is not limited to direct, indirect, incidental, or consequential damages.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">7. Modification of Terms</h3>
                  <p className="sub_heading-2 lh">We reserve the right to modify these Terms of Service at any time. It is your responsibility to review these terms regularly for changes.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">8. Termination</h3>
                  <p className="sub_heading-2 lh">We reserve the right to terminate or suspend access to MalSearch at our discretion, without notice, for any reason, including if we believe that you have violated these terms.</p>
                </div>

                <div className="scd_sec_content mt-5">
                  <h3 className="home_features_itm_cn">9. Contact Us</h3>
                  <p className="sub_heading-2 lh">If you have any questions about our Terms of Service, please contact us at <a href="mailto:contact@malsearch.com">Contact@malsearch.com</a>.<br /><br />
                    By using MalSearch, you agree to the terms outlined in these Terms of Service.
                  </p>
                </div>

              </div>
            </div>


          </div>
        </section>
      </div>
    </>
  );
};


export default Terms;
