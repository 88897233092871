import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../component/PaymentForm';
import config from '../config';

// Publishable key
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

function CheckoutPayment() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
}

export default CheckoutPayment;
