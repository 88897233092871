import React, { useState } from "react";
import emailsvg from "../assets/img/email.svg";
import lock from "../assets/img/lock.svg";
import { Link, useNavigate } from "react-router-dom";
import { postDataService } from "../userService";
import Swal from "sweetalert2";

const LoginTwoFactor = () => {
  let navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [otpError, setOTPError] = useState();

  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!otp) {
      setOTPError("Otp is required!");
      return false;
    } else {
      setOTPError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      var user = {
        loginStep2VerificationToken: localStorage.getItem(
          "loginStep2VerificationToken"
        ),
        twofaToken: otp,
      }
      await postDataService('loginStep2', new URLSearchParams(user))
        .then(async response => {
          if (response.status === 'success') {
            localStorage.setItem('accessToken', response.user.token);
            localStorage.setItem('userFirstName', response.user.firstName);
            localStorage.setItem('userLastName', response.user.lastName === undefined ? '' : response.user.lastName);
            localStorage.setItem('userId', response.user._id);
            localStorage.setItem('email', response.user.email);
            localStorage.setItem('userImage', response.user.image);
            localStorage.setItem('permission', response.user.permission);
            setIsAlertError('');
            setIsAlertSuccess(response.message);
            // if (response.user.isLogin) {
            //   const result = await Swal.fire({
            //     title: 'You’ve been signed in from a different device',
            //     icon: 'warning',
            //     showCancelButton: false,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: `Yes!`
            //   });
            //   if (result.isConfirmed) {
            //     if (window.localStorage.getItem('page')) {
            //       navigate('/project', { state: window.localStorage.getItem('filter') });
            //     } else {
            //       if (response.user.planStatus) {
            //         navigate("/");
            //       } else {
            //         navigate("/plans");
            //       }
            //     }
            //   } else {
            //     if (window.localStorage.getItem('page')) {
            //       navigate('/project', { state: window.localStorage.getItem('filter') });
            //     } else {
            //       if (response.user.planStatus) {
            //         navigate("/");
            //       } else {
            //         navigate("/plans");
            //       }
            //     }
            //   }
            // } else {
            if (window.localStorage.getItem('page')) {
              const newState = {
                filter: window.localStorage.getItem('filter'),
                currentPage: window.localStorage.getItem('currentPage'),
              };
              navigate('/project', { state: newState });
            } else {
              if (response.user.planStatus) {
                navigate("/");
              } else {
                navigate("/plans");
              }
            }
            // }
          } else {
            setIsAlertSuccess('');
            setIsAlertError(response.message);
          }
        });
    }
  }

  return (
    <>
      <div class="main_sec">
        <div class="close-btn">
          {/* <Link to="/" class="close">&#9932;</Link> */}
        </div>
        <div class="st_1">
          <div class="st_1_in">
            <h2 class="heading_typ1">Login to two factor authenticate</h2>
            {isAlertError === '' ? '' :
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isAlertError}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "#f8d7da"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                </button>
              </div>
            }
            {isAlertSuccess === '' ? '' :
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{isAlertSuccess}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "rgb(209 231 221)"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                </button>
              </div>
            }
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    {/* <label>Otp</label> */}
                    <div class="form-group-item">
                      <div class="form-group-img"><img src={lock} /></div>
                      <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder="Enter otp" class="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {otpError}
                    </small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <button class="btn btn_primary" type="submit" onClick={handleSubmit}>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default LoginTwoFactor;