import React, { useEffect, useState } from "react";
import lock from "../assets/img/lock.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postDataService } from "../userService";

const ResetPassword = () => {
  let navigate = useNavigate();
  var data = useParams();
  const [newPassword, setNewPassword] = useState();
  const [reTypePassword, setReTypePassword] = useState();

  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');

  useEffect(() => {
    if (Object.keys(data).length == 0) {
      navigate('/login');
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault();
    if (newPassword === reTypePassword) {

      let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      if (newPassword.match(check)) {
        var user = {
          password: newPassword,
          userId: data.token
        }
        await postDataService('web/reset_password', new URLSearchParams(user))
          .then(response => {
            if (response.status === 'success') {

              setIsAlertError('');
              setIsAlertSuccess(response.message);
              localStorage.clear()
              navigate("/login");

            } else {
              setIsAlertSuccess('');
              setIsAlertError(response.message);
            }
          });
      } else {
        setIsAlertSuccess('');
        setIsAlertError('password must be at least 8 characters long with one uppercase one lowercase');
      }

    } else {
      setIsAlertSuccess('');
      setIsAlertError('New Password and re-type password not match!');
    }
  }

  return (
    <>
      <div class="main_sec">
        <div className="home_bg">
          <section className="home_search">
            <div className="container">
              <div className="row jcenter pricing_head mt-5">
                <div className="col-lg-8 col-md-8 col-8">
                  <div className="home_features">
                    <h5 className="heading_typ4">New Credentials</h5>
                    <p className="" style={{ "textAlign": "center" }}>Password must be at least 8 characters long.</p>
                    <p className="" style={{ "textAlign": "center" }}>Password must contain at least one upper case. one lower case</p>
                    <p className="" style={{ "textAlign": "center" }}>Password must contain at least one number or special character.</p>
                  </div>
                </div>
              </div>
              <div class="st_1_change">
                <div class="st_1_in">
                  <h2 class="heading_typ1"></h2>
                  {isAlertError === '' ? '' :
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                      <strong>{isAlertError}</strong>
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                        "float": "right", "border": "none",
                        "background": "#f8d7da"
                      }}>
                        <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                      </button>
                    </div>
                  }
                  {isAlertSuccess === '' ? '' :
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                      <strong>{isAlertSuccess}</strong>
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                        "float": "right", "border": "none",
                        "background": "rgb(209 231 221)"
                      }}>
                        <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                      </button>
                    </div>
                  }
                  <form>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>New Password</label>
                          <div class="form-group-item">
                            <div class="form-group-img"><img src={lock} /></div>
                            <input type="password" onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter new password" class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Re-Type Password</label>
                          <div class="form-group-item">
                            <div class="form-group-img"><img src={lock} /></div>
                            <input type="password" onChange={(e) => setReTypePassword(e.target.value)} placeholder="Enter re-type password" class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn_primary" type="submit" onClick={handleSubmit}>Submit</button>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-0">
                          <p><Link to="/login">Cancel</Link></p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
    </>
  );
};


export default ResetPassword;