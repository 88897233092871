// import React, { useRef } from 'react';

// const CopyToClipboardButton = ({ textToCopy }) => {
//   const textAreaRef = useRef(null);

//   const copyToClipboard = () => {
//     console.log(textAreaRef.current);
//     // Select the text inside the textarea
//     // textAreaRef.current.select();
//     // Execute the copy command
//     document.execCommand('copy');
//   };

//   return (
//     <div>
//       <textarea
//         readOnly
//         ref={textAreaRef}
//         value={textToCopy}
//         style={{ position: 'absolute', left: '-9999px' }}
//       />
//       <button onClick={() => {navigator.clipboard.writeText("hello")}}>Copy to Clipboard</button>
//     </div>
//   );
// };

// export default CopyToClipboardButton;

import React, { useRef } from 'react';

const CopyToClipboardButton = ({ text }) => {
  const textAreaRef = useRef(null);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard:', text);
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
    }
  };

  return (
    <div>
      <textarea ref={textAreaRef} value={text} readOnly style={{ position: 'absolute', left: '-9999px' }} />
      <button onClick={handleCopyClick}>Copy to Clipboard</button>
    </div>
  );
};

const Test = () => {
  const textToCopy = 'This is the text you want to copy.';

  return (
    <div>
      <h1>React Clipboard Example</h1>
      <CopyToClipboardButton text={textToCopy} />
    </div>
  );
};

export default Test;