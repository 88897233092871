import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './frontend/Home';
import Header from './frontend/Header';
import Footer from './frontend/Footer';
import Login from './frontend/Login';
import Signup from './frontend/Signup';
import Listing from './frontend/Listing';
import Listing1 from './frontend/listing1';
import PricingPlan from './frontend/pricing-plan';
import Privacy from './frontend/privacy';
import ProjectDetail from './frontend/projectDetail';
import Terms from './frontend/Terms';
import HeaderSearch from './frontend/HeaderSearch';
import AboutUs from './frontend/AboutUs';
import Account from './frontend/Account';
import Headeraccount from './frontend/Headeraccount';
import Checkout from './frontend/Checkout';
import Protected from './component/Protected';
import PrivateRoute from './component/PrivateRoute';
import { useState } from 'react';
import ContactUs from './frontend/ContactUs';
import Highlighter from './frontend/test';
import LoginTwoFactor from './frontend/LoginTwoFactor';
import Enable2fa from './frontend/Enable-2fa';
import ChangePassword from './frontend/ChangePassword';
import ForgotPassword from './frontend/forgotPassword';
import ResetPassword from './frontend/ResetPassword';
import BookMark from './frontend/Bookmark';
import CheckoutPayment from './frontend/checkoutPayment';
import FileManager from './frontend/fileManager';

function App() {
  const [isLogged, setIsLogged] = useState(true);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<><Header /><Login /></>} exact />
          <Route path="/login-with-otp" element={<><Header /><LoginTwoFactor /></>} exact />
          <Route path="/forgot-password" element={<><Header /><ForgotPassword /></>} exact />
          <Route path="/reset-password/:token" element={<><Header /><ResetPassword /></>} exact />
          <Route path="/signup" element={<><Header /><Signup /></>} exact />
          <Route path="/test" element={<><Highlighter /></>} exact />
          <Route path="/" element={<><Header /><Home /><Footer /></>} />
          <Route path="/project" element={<><Header /><Listing /><Footer /></>} exact />
          <Route path="/listing1" element={<><Header /><Listing1 /><Footer /></>} exact />
          <Route path="/plans" element={<><Header /><PricingPlan /><Footer /></>} exact />
          <Route path="/privacy-policy" element={<><Header /><Privacy /><Footer /></>} exact />
          <Route path="/terms-of-service" element={<><Header /><Terms /><Footer /></>} exact />
          <Route path="/about-us" element={<><Header /><AboutUs /><Footer /></>} exact />
          <Route path="/contact-us" element={<><Header /><ContactUs /><Footer /></>} exact />
          <Route path="/checkout" element={<><Header /><Checkout /><Footer /></>} exact />
          <Route path="/checkout-payment" element={<CheckoutPayment />} exact />
          <Route exact element={<PrivateRoute isLogged={isLogged} />}>
            <Route path="/account" element={<Protected isSignedIn={isLogged}><Header /><Account /><Footer /></Protected>} exact />
            <Route path="/bookmarked" element={<Protected isSignedIn={isLogged}><Header /><BookMark /><Footer /></Protected>} exact />
            <Route path="/enable-two-factor" element={<Protected isSignedIn={isLogged}><Header /><Enable2fa /><Footer /></Protected>} exact />
            <Route path="/project-detail/:_id" element={<><Header /><ProjectDetail /><Footer /></>} exact />
            <Route path="/change-password" element={<><Header /><ChangePassword /><Footer /></>} exact />
            <Route path="/project/:productId" element={<><Header /><FileManager /><Footer /></>} exact />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;




