
import React, { useEffect, useState } from "react";
import DB from "../assets/img/DB.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../component/PaymentForm';
import config from "../config";
// Publishable key
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const [planList, setPlanList] = useState(location.state === null ? [] : [location.state]);
   const [planAmount, setPlanAmount] = useState();
   const [planId, setPlanId] = useState();

   useEffect(() => {
      const token = window.localStorage.getItem('accessToken');
      if (token === null) {
         navigate("/login");
      }
      if (planList.length === 0) {
         console.log('Please choose plan.');
         navigate("/plans");
      } else {
         setPlanAmount(planList[0].totalPrice);
         setPlanId(planList[0]._id);
      }
   }, [planAmount]);

   return (
      <>
         <div className="home_bg mt-5 mb-5">
            <div className="container">
               <div className="row jcenter">
                  <div className="col-md-6 mt-5">
                     <h4>Checkout</h4>
                     {planList.map((item, i) => {
                        return (
                           <div className="checkout mt-3">
                              <div className="card-part d-flex">
                                 <div className="taj-1 mr-3">
                                    <img src={DB} />
                                 </div>
                                 <div className="content-box">
                                    <h5>{item.name}</h5>
                                    <p>{item.description}</p>
                                    <h6>${item.price}</h6>
                                 </div>
                              </div>
                              {item.discount === null ? '' :
                                 <>
                                    <hr />
                                    <div className="form-group mt-4 mb-4">
                                       <div className="bill-info d-flex">
                                          <h6>Discount</h6>
                                          <h6>${item.discountPrice}</h6>
                                       </div>
                                    </div>
                                 </>
                              }
                              <hr />
                              <div className="form-group mt-4 mb-4">
                                 <div className="bill-info d-flex">
                                    <h6>Subtotal</h6>
                                    <h6>${item.totalPrice}</h6>
                                 </div>
                              </div>
                              <hr />
                              <div className="form-group mt-4 mb-4">
                                 <div className="bill-info d-flex">
                                    <h6>Total</h6>
                                    <h6>${item.totalPrice}</h6>
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                     {planList.length === 0 && (
                        <h4 className="text-danger text-center mt-3">Select a plan!</h4>
                     )}
                  </div>
                  <div className="col-md-4 mt-5">
                     <Elements stripe={stripePromise}>
                        <PaymentForm amount={planAmount} id={planId} />
                     </Elements>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Checkout;