import React, { useRef, useState } from "react";
import Server1 from "../assets/img/Server1.png";
import Firewall1 from "../assets/img/Firewall1.png";
import AntiVirus1 from "../assets/img/AntiVirus1.png";
import group_4 from "../assets/img/Group 1171276459.svg";
import { Link, useNavigate } from "react-router-dom";
import { postDataService } from "../userService";
import LoaderComponent from "../component/LoaderComponent";
import ReCAPTCHA from "react-google-recaptcha";


const Signup = () => {
  const history = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [isButton, setIsButton] = useState(false);
  const captchaRef = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const allowedCharacters = /^[a-zA-Z]+$/; // Only allow alphabetical characters

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  // Validation
  const handleValidation = (name, value) => {
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,-/])[A-Za-z\d@$!%*?&.,-/]{8,}$/;
    // Check if the entered name matches the regex pattern
    if (name === 'firstName') {
      if (!value || value.trim() === '') {
        setFirstNameError("First name is required!");
        formIsValid = false;
      } else if (value.length > 50) {
        setFirstNameError("First name must be limited to 50 characters!");
        formIsValid = false;
      } else if (!nameRegex.test(value)) {
        setFirstNameError("Invalid first name!");
        formIsValid = false;
      } else {
        setFirstNameError("");
        formIsValid = true;
      }
    } else if (name === 'lastName') {
      if (!value) {
        setLastNameError("");
        formIsValid = true;
      } else {
        if (value.length > 50) {
          setLastNameError("Last name must be limited to 50 characters!");
          formIsValid = false;
        } else if (!nameRegex.test(value)) {
          setLastNameError("Invalid last name!");
          formIsValid = false;
        } else {
          setLastNameError("");
          formIsValid = true;
        }
      }
    } else if (name === 'email') {
      if (!value || value.trim() === '') {
        setEmailError("Email is required!");
        formIsValid = false;
      } else if (value.length > 100) {
        setEmailError("Email must be limited to 100 characters!");
        formIsValid = false;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        setEmailError("Invalid email address!");
        formIsValid = false;
      } else {
        setEmailError("");
        formIsValid = true;
      }
    } else if (name === 'password') {
      if (!value) {
        setPasswordError("Password is required!");
        formIsValid = false;
      } else if (value.length > 64) {
        setPasswordError("Password must be limited to 64 characters!");
        formIsValid = false;
      } else if (!passwordRegex.test(value)) {
        setPasswordError("Password must be at least 8 characters long with 1 uppercase, 1 lowercase, 1 special character, and 1 number!");
        formIsValid = false;
      } else {
        setPasswordError("");
        formIsValid = true;
      }
    }

    return formIsValid;
  };

  const handleFirstNameChange = (event) => {
    const value = event.target.value;
    if (allowedCharacters.test(value) || value === '') {
      setFirstName(value);
      setIsButton(handleValidation('firstName', value));
    }
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;
    if (allowedCharacters.test(value) || value === '') {
      setLastName(value);
      setIsButton(handleValidation('lastName', value));
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsButton(handleValidation('email', value));
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setIsButton(handleValidation('password', value));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (recaptchaValue) {
        var featureData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          recaptchaToken: recaptchaValue
        }
        setLoading(true);
        await postDataService(`add_user`, new URLSearchParams(featureData))
          .then(async response => {
            if (response.status === 'success') {
              var user = {
                email: email,
                password: password
              }
              await postDataService('login', new URLSearchParams(user))
                .then(response => {
                  if (response.status === 'success') {
                    localStorage.setItem('accessToken', response.user.token);
                    localStorage.setItem('userFirstName', response.user.firstName);
                    localStorage.setItem('userLastName', response.user.lastName === undefined ? '' : response.user.lastName);
                    localStorage.setItem('userId', response.user._id);
                    localStorage.setItem('email', response.user.email);
                    localStorage.setItem('userImage', response.user.image);
                    localStorage.setItem('permission', response.user.permission);
                    setIsAlertError('');
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPassword('');
                    setIsAlertError('');
                    setIsAlertSuccess('Signup successfully!');
                    history("/plans");
                  } else {
                    setIsAlertSuccess('');
                    setIsAlertError(response.message);
                  }
                });
            } else {
              setIsAlertSuccess('');
              setIsAlertError(response.message);
            }
          });
        setLoading(false);
      } else {
        setIsAlertError('Captcha Not Valid!');
      }

    }
  }

  return (
    <>
      <div>
        <LoaderComponent active={loading} />
      </div>
      <div class="home_bg">
        <div class="st_2">
          <div class="row m-0">
            <div class="col-md-6 p-0">
              <div class="st_2_in_ss">
                <div class="st_2_in">
                  <h3 class="heading_typ1">Sign Up</h3>
                  {isAlertError === '' ? '' :
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                      <strong>{isAlertError}</strong>
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                        "float": "right", "border": "none",
                        "background": "#f8d7da"
                      }}>
                        <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                      </button>
                    </div>
                  }
                  {isAlertSuccess === '' ? '' :
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                      <strong>{isAlertSuccess}</strong>
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                        "float": "right", "border": "none",
                        "background": "rgb(209 231 221)"
                      }}>
                        <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                      </button>
                    </div>
                  }

                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>First Name <span className="text-danger">*</span></label>
                          <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="Enter first name" class="form-control" />
                          <small className="text-danger form-text">
                            {firstNameError}
                          </small>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="Enter last name" class="form-control" />
                          <small className="text-danger form-text">
                            {lastNameError}
                          </small>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Email Address <span className="text-danger">*</span></label>
                          <input type="text" value={email} onChange={handleEmailChange} placeholder="Enter email address" class="form-control" />
                          <small className="text-danger form-text">
                            {emailError}
                          </small>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Password <span className="text-danger">*</span></label>
                          <input type="password" value={password} onChange={handlePasswordChange} placeholder="***********" class="form-control" />
                          <small className="text-danger form-text">
                            {passwordError}
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          {/* <HCaptcha
                            sitekey="7149bae4-3170-435a-ae72-52a0bee63452"
                            // onLoad={onLoad}
                            onVerify={setToken}
                            ref={captchaRef}
                          /> */}
                          <ReCAPTCHA
                            sitekey="6LfrXjkpAAAAAClfgYvw9l1uhDE9XemxXdhEMhLk" // Replace with your site key
                            onChange={handleRecaptchaChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn_primary" disabled={!isButton} type="submit">Signup</button>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-0">
                          <p>Have an account already? <Link to="/login">Sign in</Link></p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6 p-0 d-none d-md-block">
              <div class="st_2_con">

                <h3 class="heading_type3">MalSearch has Over 10,000,000 Lines of Malware Source Code!</h3>
                <div class="st_2_itms">
                  <div class="st_2_itm">
                    <div class="st_2_itm_img"><img src={Server1} /></div>
                    <div class="st_2_itm_con">
                      <h4>50+ Programming Languages</h4>
                      <p>Explore Malware Code in 50+ Languages for Comprehensive Analysis.</p>
                    </div>
                  </div>
                  <div class="st_2_itm">
                    <div class="st_2_itm_img"><img src={Firewall1} /></div>
                    <div class="st_2_itm_con">
                      <h4>Search for Any OS</h4>
                      <p>Effortlessly Search Malware Projects Tailored to Any Operating System.</p>
                    </div>
                  </div>
                  <div class="st_2_itm">
                    <div class="st_2_itm_img"><img src={AntiVirus1} /></div>
                    <div class="st_2_itm_con">
                      <h4>Easily Filter and Analyze Code</h4>
                      <p>Effortlessly Filter and Analyze Malware Code for In-Depth Understanding.</p>
                    </div>
                  </div>
                  <div class="st_2_itm">
                    <div class="st_2_itm_img"><img src={group_4} /></div>
                    <div class="st_2_itm_con">
                      <h4>Ever-Growing Repositories</h4>
                      <p>Dive into a Continuously Growing Pool of Malware Repositories.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Signup;
